/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import { Link } from "react-router-dom";
import isEmpty from "lodash.isempty";
import { HomeCafe_CafeFragment } from "../graphql/generated/schema";
import { Routes } from "../constants/routes";
import BadgePng from "../assets/images/badge.png";
import NoImage from "../assets/images/no_image.jpg";

type Props = {
  node: HomeCafe_CafeFragment;
};

export const HomeCafeCard: React.FC<Props> = ({ node }) => {
  const { access, brand, description, path, branchName, official, pictures } = node;
  const cafeName = `${brand.name} ${branchName ? ` ${branchName}` : ""}${(description && `【${description}】`) || ""}`;

  return (
    <div css={styles.wrapper}>
      <div css={styles.card}>
        <Link css={styles.link} to={`/${Routes.CAFES}/${path}`}>
          <div css={styles.imageContainer}>
            <div
              css={styles.image}
              style={{ backgroundImage: `url(${(!isEmpty(pictures) && pictures[0].url) || NoImage})` }}
            />
          </div>
          <div css={styles.titleContainer}>
            {official ? (
              <h3 css={styles.cafeName}>
                <span>
                  {cafeName}
                  <img css={styles.officialBadge} src={BadgePng} height="20" width="20" alt="" />
                </span>
              </h3>
            ) : (
              <h3 css={styles.cafeName}>{cafeName}</h3>
            )}
            {access && <p css={styles.access}>{access}</p>}
          </div>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    marginTop: 24,
    width: "100%",
    "@media (min-width: 767px)": {
      flex: "0 0 50%",
      paddingRight: 8,
      paddingLeft: 8,
      width: "50%",
    },
    "@media (min-width: 992px)": {
      flex: "0 0 33.3333%",
      width: "33.3333%",
    },
  }),
  card: css({
    position: "relative",
    height: "100%",
  }),
  link: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    borderRadius: 6,
    overflow: "hidden",
    position: "relative",
    height: "100%",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
    display: "block",
  }),
  imageContainer: css({
    backgroundColor: "var(--lighterTextColor)",
    position: "relative",
    width: "100%",
    height: 165,
  }),
  image: css({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  }),
  titleContainer: css({
    padding: 16,
    display: "flex",
    flexDirection: "column",
  }),
  cafeName: css({
    color: "var(--textColor)",
    display: "inline-flex",
    fontWeight: 700,
    fontSize: 15,
    lineHeight: 1.65,
    margin: 0,
  }),
  officialBadge: css({
    display: "inline",
    verticalAlign: "top",
    width: 20,
    height: 20,
  }),
  access: css({
    color: "var(--subTextColor)",
    fontSize: 12,
    margin: "10px 0 0",
  }),
};
