import { createClient } from "microcms-js-sdk";

export const microCmsClient = createClient({
  serviceDomain: process.env.REACT_APP_MICROCMS_SERVICE_DOMAIN!,
  apiKey: process.env.REACT_APP_MICROCMS_API_KEY!,
});

export type ArticleResult = {
  body: string;
  createdAt: string;
  cover: {
    url: string;
    height: number;
    width: number;
  };
  description: string | null;
  id: string;
  publishedAt: string;
  revisedAt: string;
  title: string;
  updatedAt: string;
};
