import React from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const isProductionEnv = process.env.REACT_APP_ENV === "production";

// Note: Track only pruduction build
export function usePageViews() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (isProductionEnv) {
      ReactGA.initialize("UA-15093236-1");
    }
  }, []);

  React.useEffect(() => {
    if (isProductionEnv) {
      ReactGA.set({ page: pathname });
      ReactGA.pageview(pathname);
    }
  }, [pathname]);
}
