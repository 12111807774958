/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import { FiMapPin } from "react-icons/fi";
import { Link } from "react-router-dom";
import { SearchListItem_StationFragment } from "../graphql/generated/schema";
import { Routes } from "../constants/routes";

export const HeaderSearchListItem: React.FC<{
  station: SearchListItem_StationFragment;
}> = ({ station }) => {
  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        <Link
          key={station.id}
          css={styles.stationLink}
          to={{
            pathname: `/${Routes.SEARCH}`,
            search: `?lat=${station.latitude}&lng=${station.longitude}&name=${station.name}`,
          }}
        >
          <FiMapPin size={24} />
          <div css={styles.station}>
            <h3 css={styles.stationName}>{station.name}</h3>
            <p css={styles.stationRoute}>{station.route}</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    overflowX: "hidden",
    overflowY: "auto",
    position: "relative",
  }),
  container: css({
    borderBottom: "1px solid var(--borderColor)",
  }),
  stationLink: css({
    alignItems: "center",
    color: "var(--textColor)",
    display: "flex",
    paddingTop: 12,
    paddingBottom: 12,
    position: "relative",
    transition: "all 0.2s var(--transition)",
    svg: css({
      color: "var(--mutedTextColor)",
      display: "block",
    }),
    "&:hover": {
      color: "var(--defaultBrandColor)",
      svg: css({
        color: "var(--defaultBrandColor)",
      }),
    },
  }),
  station: css({
    maxWidth: "calc(100% - 30px)",
    paddingLeft: 8,
  }),
  stationName: css({
    color: "var(--textColor)",
    fontSize: 14,
    fontWeight: 700,
    margin: 0,
  }),
  stationRoute: css({
    color: "var(--subTextColor)",
    fontSize: 12,
    margin: 0,
  }),
};
