/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import React from "react";
import validator from "validator";
import { v4 as uuidv4 } from "uuid";
import { ExternalLink } from "./ExternalLink";

const linkTagRegex = /(<a(?: .+?)?>.*?<\/a>)/g;
const RowLink: React.FC<{
  content: string;
  to: string;
}> = ({ content, to }) => (
  <ExternalLink to={to} target="_blank">
    {content}
  </ExternalLink>
);

// aタグが変換されるようにする
const convertNoteLink = (text: string) => {
  const splitedContent = text.replace(/(<a(?: .+?)?>.*?<\/a>)/g, "$1,");
  return splitedContent.split(/,/g).map((str: string) => {
    if (str.match(linkTagRegex) !== null) {
      const matchedLink = str.match(/(href=".*?")/g);
      const to = matchedLink ? matchedLink[0].slice(6, -1) : null;
      const matchedContent = str.match(/(>.*?<\/a>)/);
      const content = matchedContent ? matchedContent[0].slice(1, -4) : null;

      return to && content && <RowLink key={uuidv4()} to={to} content={content} />;
    }

    return str;
  });
};

export const CafeRow: React.FC<{
  style?: SerializedStyles;
  children?: React.ReactNode;
  content?: string;
  title: string | null;
  embedHtml?: boolean;
}> = ({ children, content, title, style, embedHtml }) => {
  if (title === null) return null;
  if (!content && !children) return null;

  const renderContent = () => {
    if (!content) return null;

    return validator.isURL(content) ? (
      <ExternalLink to={content} target="_blank">
        {content}
      </ExternalLink>
    ) : (
      convertNoteLink(content)
    );
  };

  return (
    <div css={[styles.wrapper, style]}>
      <div css={styles.row}>
        <div css={styles.title}>{title}</div>
        {!embedHtml && (
          <div css={styles.content}>
            {renderContent()}
            {children}
          </div>
        )}
        {embedHtml && content && (
          <div
            css={styles.embedContent}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    position: "relative",
    "@media (min-width: 992px) ": {
      marginBottom: 10,
      "&:last-of-type": {
        marginBottom: 0,
      },
    },
  }),
  row: css({
    alignItems: "stretch",
    display: "flex",
    flexWrap: "wrap",
    "@media (max-width: 991px)": {
      borderBottom: "1px solid var(--borderColor)",
    },
  }),
  title: css({
    alignItems: "center",
    backgroundColor: "var(--defaultBgColor)",
    color: "var(--textColor)",
    display: "flex",
    fontSize: 12,
    fontWeight: 700,
    justifyContent: "center",
    "@media (max-width: 991px)": {
      boxShadow: "inset -1px 0 0 var(--borderColor)",
      flexBasis: "30%",
      padding: 8,
    },
    "@media (min-width: 992px) ": {
      flexBasis: 150,
      paddingBottom: 15,
      paddingTop: 15,
    },
  }),
  content: css({
    color: "var(--textColor)",
    fontWeight: "normal",
    "@media (max-width: 991px)": {
      flexBasis: "70%",
      fontSize: 12,
      lineHeight: "16px",
      padding: "10px 8px",
    },
    "@media (min-width: 992px) ": {
      flexBasis: "calc(100% - 150px)",
      fontSize: 14,
      lineHeight: "20px",
      padding: 15,
    },
  }),
  embedContent: css({
    color: "var(--textColor)",
    fontWeight: "normal",
    "@media (max-width: 991px)": {
      flexBasis: "70%",
      padding: "10px 8px",
    },
    "@media (min-width: 992px) ": {
      flexBasis: "calc(100% - 150px)",
      padding: 15,
    },
    p: css({
      margin: 0,
      "@media (max-width: 991px)": {
        fontSize: 12,
        lineHeight: 1.35,
      },
      "@media (min-width: 992px) ": {
        fontSize: 14,
        lineHeight: 1.45,
      },
    }),
  }),
};
