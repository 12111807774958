/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { MdExpandMore, MdCheck } from "react-icons/md";
import { useSearchCafeUsagePickerQuery } from "../graphql/generated/schema";

type Props = {
  selectedUsageIds?: string[];
  onChange: (usageIds: string[]) => void;
};

export const SearchCafeUsagePicker: React.FC<Props> = ({ selectedUsageIds, onChange }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const selectedUsagesIdsSet = new Set(selectedUsageIds);
  const isUsageSelected = selectedUsagesIdsSet.size > 0;
  const { loading, error, data } = useSearchCafeUsagePickerQuery();

  const handleUsageChange = (id: string) => {
    if (selectedUsagesIdsSet.has(id)) {
      selectedUsagesIdsSet.delete(id);
    } else {
      selectedUsagesIdsSet.add(id);
    }
    onChange([...selectedUsagesIdsSet]);
  };

  if (error) {
    console.error(error);
  }

  return (
    <div css={styles.wrapper}>
      <OutsideClickHandler onOutsideClick={() => setDropdownOpen(false)}>
        <button
          type="button"
          css={[styles.button, isUsageSelected && styles.buttonActive]}
          onClick={() => setDropdownOpen(!isDropdownOpen)}
          disabled={loading}
        >
          利用目的
          <MdExpandMore size={20} />
        </button>
        {isDropdownOpen && (
          <div css={styles.dropdown}>
            <button
              css={[styles.dropdownButton, !isUsageSelected && styles.dropdownButtonActive]}
              onClick={() => onChange([])}
            >
              <span css={[styles.dropdownText, !isUsageSelected && styles.dropdownTextActive]}>すべての利用目的</span>
              {!isUsageSelected && <MdCheck size={20} />}
            </button>
            {data?.usages?.edges.map((edge) => {
              const { id, name } = edge.node!;
              const isActive = selectedUsagesIdsSet.has(id);

              return (
                <button
                  key={id}
                  css={[styles.dropdownButton, isActive && styles.dropdownButtonActive]}
                  type="button"
                  onClick={() => handleUsageChange(id)}
                >
                  <span css={[styles.dropdownText, isActive && styles.dropdownTextActive]}>{name}</span>
                  {isActive && <MdCheck size={20} />}
                </button>
              );
            })}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

const styles = {
  wrapper: css({
    position: "relative",
  }),
  button: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    borderRadius: 50,
    border: "1px solid var(--defaultComponentBgColor)",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 15,
    fontSize: 14,
    lineHeight: "20px",
    color: "var(--defaultTextColor)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
    display: "flex",
    alignItems: "center",
    "&:hover": css({
      border: "1px solid var(--defaultBrandColor)",
      fontWeight: "medium",
    }),
    svg: css({
      marginLeft: 5,
    }),
  }),
  buttonActive: css({
    backgroundColor: "var(--defaultBrandColor)",
    border: "1px solid var(--defaultBrandColor)",
    fontWeight: "bold",
    boxShadow: "none",
    "&:hover": css({
      backgroundColor: "var(--defaultBrandColor)",
      border: "1px solid var(--defaultBrandColor)",
    }),
  }),
  dropdown: css({
    backgroundColor: "var(--whiteColor)",
    borderRadius: 8,
    marginTop: 8,
    marginBottom: 24,
    width: 225,
    paddingTop: 8,
    paddingBottom: 8,
    boxShadow: "var(--boxShadow)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "absolute",
    zIndex: 100,
    top: "100%",
    left: 0,
  }),
  dropdownButton: css({
    backgroundColor: "var(--whiteColor)",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 14,
    lineHeight: "20px",
    width: "100%",
    textAlign: "left",
    display: "flex",
    "&:hover": css({
      backgroundColor: "var(--grayLighter)",
    }),
  }),
  dropdownButtonActive: css({
    svg: css({
      color: "var(--defaultBrandColor)",
      display: "block",
      marginLeft: "auto",
    }),
  }),
  dropdownText: css({
    width: "calc(100% - 20px)",
  }),
  dropdownTextActive: css({
    fontWeight: "bold",
  }),
};
