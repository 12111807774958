/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import React from "react";

export type Props = {
  children?: React.ReactNode;
  style?: SerializedStyles;
  transition?: string;
};

const styles = {
  wrapper: css({
    position: "relative",
  }),
  entering: css({
    opacity: 0,
    zIndex: 999,
  }),
  entered: css({
    opacity: 1,
    zIndex: 999,
  }),
  exiting: css({
    opacity: 0,
  }),
  exited: css({
    opacity: 0,
    display: "none",
    zIndex: -1,
  }),
};

export const SearchGroup: React.FC<Props> = ({ children, style, transition }) => {
  if (!children) return null;

  return (
    <div
      css={[
        styles.wrapper,
        style,
        transition === "entering" && styles.entering,
        transition === "entered" && styles.entered,
        transition === "exiting" && styles.exiting,
        transition === "exited" && styles.exited,
      ]}
    >
      {children}
    </div>
  );
};
