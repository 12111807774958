/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import isEmpty from "lodash.isempty";
import { Routes } from "../constants/routes";
import AltArticleImage from "../assets/images/article.png";
import { ArticleResult } from "../utils/microCmsClient";

type Props = {
  article: ArticleResult;
};

export const ArticleCard: FC<Props> = ({ article }) => {
  const { id, createdAt, cover, title } = article;

  return (
    <div css={styles.wrapper}>
      <div css={styles.card}>
        <Link css={styles.link} to={`/${Routes.ARTICLES}/${id}`}>
          <div css={styles.imageContainer}>
            <div
              css={styles.image}
              style={
                isEmpty(cover)
                  ? { backgroundImage: `url(${AltArticleImage})` }
                  : { backgroundImage: `url(${cover.url})` }
              }
            />
          </div>
          <div css={styles.titleContainer}>
            <h2 css={styles.title}>{title}</h2>
            <div css={styles.createdAt}>{dayjs(createdAt).format("YYYY年MM月DD日")}</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    "@media screen and (max-width: 991px)": {
      width: "100%",
    },
    "@media screen and (min-width: 992px)": {
      flex: "0 0 50%",
      padding: "0 10px",
      width: "50%",
    },
  }),
  card: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    border: "1px solid var(--articleCardBorderColor)",
    borderRadius: 3,
    margin: "0 0 20px",
    padding: 15,
  }),
  link: css({
    height: "100%",
    "@media screen and (max-width: 991px)": {
      alignItems: " center",
      display: "flex",
      flexWrap: "wrap",
    },
    "@media screen and (min-width: 992px)": {
      display: "block",
    },
  }),
  imageContainer: css({
    backgroundColor: "var(--lighterTextColor)",
    position: "relative",
    "@media screen and (max-width: 991px)": {
      height: 80,
      width: 80,
    },
    "@media screen and (min-width: 992px)": {
      height: 150,
      marginBottom: 10,
      width: "100%",
    },
  }),
  image: css({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  }),
  titleContainer: css({
    "@media screen and (max-width: 991px)": {
      paddingLeft: 10,
      width: "calc(100% - 80px)",
    },
  }),
  title: css({
    color: "var(--textColor)",
    fontWeight: 700,
    fontSize: 16,
    margin: "0 0 10px",
  }),
  createdAt: css({
    color: "var(--subTextColor)",
    fontSize: 12,
    textAlign: "right",
  }),
};
