/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import isEmpty from "lodash.isempty";
import { Routes } from "../constants/routes";
import { ArticleResult } from "../utils/microCmsClient";
import AltArticleImage from "../assets/images/article.png";

type Props = {
  article: ArticleResult;
};

export const HomeArticleCard: FC<Props> = ({ article }) => {
  const { id, cover, title } = article;

  return (
    <div css={styles.wrapper}>
      <div css={styles.card}>
        <Link css={styles.link} to={`/${Routes.ARTICLES}/${id}`}>
          <div css={styles.imageContainer}>
            <div
              css={styles.image}
              style={
                isEmpty(cover)
                  ? { backgroundImage: `url(${AltArticleImage})` }
                  : { backgroundImage: `url(${cover.url})` }
              }
            />
          </div>
          <div css={styles.titleContainer}>
            <h2 css={styles.title}>{title}</h2>
          </div>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    marginTop: 24,
    width: "100%",
    "@media (min-width: 767px)": {
      flex: "0 0 50%",
      paddingRight: 8,
      paddingLeft: 8,
      width: "50%",
    },
    "@media (min-width: 992px)": {
      flex: "0 0 33.3333%",
      width: "33.3333%",
    },
  }),
  card: css({
    position: "relative",
    height: "100%",
  }),
  link: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    borderRadius: 6,
    overflow: "hidden",
    position: "relative",
    height: "100%",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
    display: "block",
  }),
  imageContainer: css({
    backgroundColor: "var(--lighterTextColor)",
    position: "relative",
    width: "100%",
    height: 150,
  }),
  image: css({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  }),
  titleContainer: css({
    padding: 16,
  }),
  title: css({
    color: "var(--textColor)",
    fontWeight: 700,
    fontSize: 15,
    lineHeight: 1.65,
    margin: 0,
  }),
};
