/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { ExternalLink } from "./ExternalLink";
import { Routes } from "../constants/routes";
import { Locales } from "../constants/locales";
import { uri } from "../constants/uri";

export const Footer: FC = () => (
  <footer css={styles.wrapper}>
    <div css={styles.container}>
      <div css={styles.menu}>
        <div css={styles.menuGroup}>
          <h3 css={styles.menuTitle}>{Locales.FOOTER.MENU}</h3>
          <Link css={styles.menuLink} to={`/${Routes.ARTICLES}`}>
            {Locales.NAVIGATION.ARTICLES}
          </Link>
          <Link css={styles.menuLink} to={`/${Routes.MEDIA}`}>
            {Locales.NAVIGATION.MEDIA}
          </Link>
          <ExternalLink css={styles.menuLink} to={uri.dengencafeCorp} target="_blank">
            {Locales.NAVIGATION.COMPANY}
          </ExternalLink>
        </div>
        <div css={styles.menuGroup}>
          <h3 css={styles.menuTitle}>{Locales.FOOTER.CATEGORY}</h3>
          <Link css={styles.menuLink} to={`/${Routes.FORM}`}>
            {Locales.NAVIGATION.FORM}
          </Link>
          <Link css={styles.menuLink} to={`/${Routes.PRIVACY}`}>
            {Locales.NAVIGATION.PRIVACY}
          </Link>
        </div>
        <div css={styles.description}>
          <div css={styles.descriptionTitleGroup}>
            <h2 css={styles.descriptionTitle}>{Locales.FOOTER.ABOUT}</h2>
            <div css={styles.descriptionDivider} />
          </div>
          <p css={styles.descriptionText}>
            電源（コンセント）が使えるカフェを探せる
            <br />
            スマホ・タブレット・PCユーザーのための充電スポット検索サイト。
            <br />
            ノマドに必要なWiFi、禁煙喫煙、混雑具合、電源口数を掲載。
            <br />
            電源カフェでは、実地調査した情報のみを掲載しています。
          </p>
        </div>
      </div>
      <div css={styles.copyright}>
        <small>Copyright © DENGENCAFE, Inc.</small>
      </div>
    </div>
  </footer>
);

const styles = {
  wrapper: css({
    backgroundColor: "var(--footerColor)",
    position: "relative",
  }),
  container: css({
    marginLeft: "auto",
    marginRight: "auto",
    padding: "30px 15px",
    "@media screen and (min-width: 767px)": {
      maxWidth: 768,
    },
    "@media screen and (min-width: 992px)": {
      maxWidth: 1120,
    },
    "@media screen and (min-width: 1280px)": {
      width: 1120,
    },
  }),
  menu: css({
    "@media screen and (min-width: 992px)": {
      display: "flex",
      alignItems: "flex-start",
    },
  }),
  menuGroup: css({
    display: "flex",
    flexDirection: "column",
    marginBottom: 24,
    "@media screen and (min-width: 992px)": {
      flex: "0 0 180px",
    },
  }),
  menuTitle: css({
    boxShadow: "inset 5px 0 0 var(--defaultBrandColor)",
    color: "var(--whiteColor)",
    fontSize: 14,
    fontWeight: 700,
    margin: 0,
    paddingLeft: 16,
  }),
  menuLink: css({
    color: "var(--whiteColor)",
    display: "block",
    fontSize: 14,
    marginTop: 16,
    "&:hover": {
      color: "var(--defaultBrandColor)",
    },
    "@media screen and (min-width: 992px)": {},
  }),
  description: css({
    color: "var(--whiteColor)",
    fontSize: 14,
    marginBottom: 0,
    marginTop: 0,
    marginLeft: "auto",
  }),
  descriptionTitleGroup: css({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  }),
  descriptionDivider: css({
    backgroundColor: "var(--whiteColor)",
    flex: "1 0",
    height: 1,
    marginLeft: "auto",
  }),
  descriptionTitle: css({
    boxShadow: "inset 0 0 0 1px var(--whiteColor)",
    color: "var(--whiteColor)",
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 0,
    marginRight: 40,
    marginTop: 0,
    padding: 12,
  }),
  descriptionText: css({
    color: "var(--whiteColor)",
    fontSize: 14,
    marginBottom: 0,
    marginTop: 24,
  }),
  copyright: css({
    color: "var(--mutedTextColor)",
    marginTop: 40,
    textAlign: "center",
    small: css({
      fontSize: 12,
    }),
  }),
};
