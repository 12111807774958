/** @jsxImportSource @emotion/react */

import { css, keyframes } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import React from "react";
import { FiLoader } from "react-icons/fi";
import { Locales } from "../constants/locales";

type Props = {
  style?: SerializedStyles;
  loading: boolean;
  onClick: () => void;
};

const styles = {
  button: css({
    alignItems: "center",
    appearance: "none",
    background: "none",
    borderRadius: 6,
    cursor: "pointer",
    color: "var(--textColor)",
    display: "flex",
    fontSize: 13,
    fontWeight: 700,
    justifyContent: "center",
    outline: 0,
    padding: 8,
    position: "relative",
    transition: "all 0.2s var(--transition)",
    "&:hover": {
      borderColor: "var(--defaultBrandColor)",
      color: "var(--defaultBrandColor)",
    },
    svg: css({
      animation: "spin infinite 2s linear",
      color: "var(--textColor)",
      marginRight: 5,
      pointerEvents: "none",
    }),
  }),
  spin: keyframes({
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  }),
};

export const ReadMoreTextButton: React.FC<Props> = ({ loading, onClick, style }) => (
  <button type="button" css={[styles.button, style]} disabled={loading} onClick={onClick}>
    {loading && <FiLoader size={20} />}
    {Locales.LOAD_MORE}
  </button>
);
