/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "../constants/routes";
import { ArticlePage_ArticleFragment } from "../graphql/generated/schema";

export const LatestArticleCard: React.FC<{
  node: ArticlePage_ArticleFragment;
}> = ({ node }) => {
  const { contentId, image1, title } = node;

  return (
    <div css={styles.wrapper}>
      <Link css={styles.link} to={`/${Routes.ARTICLES}/${contentId}`}>
        <div css={styles.image}>{node.image1 ? <img src={image1} alt={title} /> : null}</div>
        <h2 css={styles.title}>{title}</h2>
      </Link>
    </div>
  );
};

const styles = {
  wrapper: css({
    position: "relative",
    border: "1px solid var(--borderColor)",
    borderRadius: 3,
    marginBottom: 20,
    padding: 15,
  }),
  link: css({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
  }),
  image: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    height: 48,
    width: 48,
  }),
  title: css({
    color: "var(--textColor)",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    margin: 0,
    paddingLeft: 12,
    width: "calc(100% - 48px)",
    "&:hover": css({
      color: "var(--defaultBrandColor)",
    }),
  }),
};
