/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import React from "react";
import { Link } from "react-router-dom";
import { Locales } from "../constants/locales";

type Props = {
  path: string;
  className?: string;
  style?: SerializedStyles;
};

const styles = {
  link: css({
    alignItems: "center",
    border: "1px solid var(--readMoreButtonBorderColor)",
    borderRadius: 3,
    color: "var(--subTextColor)",
    cursor: "pointer",
    display: "flex",
    fontSize: 14,
    fontWeight: 700,
    justifyContent: "center",
    padding: "12px 16px",
    transition: "all 0.2s var(--transition)",
    width: "100%",
    "&:hover": {
      borderColor: "var(--defaultBrandColor)",
      color: "var(--subTextColor)",
    },
  }),
};

export const ReadMoreLink: React.FC<Props> = ({ path, style }) => (
  <Link css={[styles.link, style]} to={path}>
    {Locales.LOAD_MORE}
  </Link>
);
