/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FC } from "react";
import { FiMenu } from "react-icons/fi";

type Props = {
  className?: string;
  isOpen: boolean;
  onClick: () => void;
};

const styles = {
  button: css({
    appearance: "none",
    background: "none",
    border: "none",
    borderRadius: 0,
    cursor: "pointer",
    marginRight: 8,
    outline: 0,
    padding: 0,
    textAlign: "center",
    transition: "all 0.15s var(--transition)",
    svg: css({
      color: "var(--textColor)",
      display: "block",
      transition: "all 0.1s var(--transition)",
    }),
    "&:disabled": {
      backgroundColor: "var(--lighterTextColor)",
      boxShadow: "none",
      color: "var(--mutedTextColor)",
      cursor: "default",
    },
    "@media (min-width: 992px)": {
      display: "none",
    },
  }),
  buttonActive: css({
    svg: css({
      color: "var(--defaultBrandColor)",
    }),
  }),
};

export const MenuHandler: FC<Props> = ({ isOpen, onClick }) => (
  <button type="button" css={[styles.button, isOpen && styles.buttonActive]} onClick={onClick}>
    <FiMenu size={24} />
  </button>
);
