/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FC, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiX } from "react-icons/fi";
import { Transition } from "react-transition-group";
import { FiSearch } from "react-icons/fi";
import { Drawer } from "./Drawer";
import { TextLogo } from "./Logo";
import { MenuHandler } from "./MenuHandler";
import { HeaderSearchListItem } from "./HeaderSearchListItem";
import { SearchHandler } from "./SearchHandler";
import { SearchGroup } from "./SearchGroup";
import { ThemeSwitch } from "./ThemeSwitch";
import { Location } from "../@types/types";
import { useDebounceValue } from "../hooks/useDebounceValue";
import { useHeaderSearchLazyQuery } from "../graphql/generated/schema";
import * as Classes from "../utils/classes";

type Props = {
  location?: Location;
};

export const Header: FC<Props> = ({ location }) => {
  const [isDrawerOpen, setDrawerOpenState] = useState<boolean>(false);
  const [isSearchOpen, setSearchOpenState] = useState<boolean>(false);
  const [fetchHeaderSearch, { data, error, refetch }] = useHeaderSearchLazyQuery();
  const { value: stationValue, setValue: setStationValue } = useDebounceValue({
    onChangeValue: useCallback(
      (value) => {
        refetch && refetch({ query: value });
      },
      [fetchHeaderSearch, refetch]
    ),
  });

  useEffect(() => {
    document.body.classList.remove(Classes.OVERLAY_OPEN);
    setDrawerOpenState(false);
    setSearchOpenState(false);
  }, [location]);

  useEffect(() => {
    if (stationValue) {
      fetchHeaderSearch({ variables: { query: stationValue } });
    }
  }, [fetchHeaderSearch, refetch, stationValue]);

  const handleOpenDrawer = useCallback(() => {
    isDrawerOpen
      ? document.body.classList.remove(Classes.OVERLAY_OPEN)
      : document.body.classList.add(Classes.OVERLAY_OPEN);
    setDrawerOpenState(!isDrawerOpen);
  }, [isDrawerOpen]);

  const handleStationValueChange = (value: string) => {
    setStationValue(value);
  };

  if (error) {
    console.error(error);
  }

  return (
    <>
      <header css={styles.wrapper}>
        <MenuHandler isOpen={isDrawerOpen} onClick={handleOpenDrawer} />
        <Link css={styles.logoLink} to="/">
          <TextLogo style={styles.textLogo} />
        </Link>
        <div css={styles.navigationContainer}>
          <ThemeSwitch />
          <SearchHandler isOpen={isSearchOpen} onClick={() => setSearchOpenState(true)} />
        </div>
      </header>
      <Drawer isOpen={isDrawerOpen} onChangeOpenState={handleOpenDrawer} />
      <Transition in={isSearchOpen} timeout={200}>
        {(state) => (
          <SearchGroup style={styles.searchGroup} transition={state}>
            <div css={styles.searchHeader}>
              <div css={styles.inputGroup}>
                <input
                  type="search"
                  css={styles.input}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  placeholder="駅名"
                  name="stationValue"
                  defaultValue={stationValue}
                  onChange={(event) => handleStationValueChange(event.target.value)}
                />
                <FiSearch css={styles.searchIcon} size={24} />
              </div>
              <button type="button" css={styles.searchCloseButton} onClick={() => setSearchOpenState(false)}>
                <FiX size={24} css={styles.closeIcon} />
              </button>
            </div>
            <div css={styles.headerSearchListContainer}>
              <div css={styles.headerSearchList}>
                {data?.stations?.edges?.map((edge) => {
                  return <HeaderSearchListItem key={edge.node.id} station={edge.node} />;
                })}
              </div>
            </div>
          </SearchGroup>
        )}
      </Transition>
    </>
  );
};

const styles = {
  wrapper: css({
    display: "flex",
    backgroundColor: "var(--defaultComponentBgColor)",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 15,
    paddingRight: 15,
    "@media screen and (max-width: 991px)": {
      justifyContent: "center",
    },
  }),
  logoLink: css({
    display: "block",
    "@media screen and (max-width: 991px)": {
      marginLeft: 10,
      marginRight: "auto",
    },
  }),
  textLogo: css({
    display: "var(--textColor)",
    paddingBottom: 9,
    paddingTop: 9,
  }),
  navigationContainer: css({
    marginLeft: "auto",
    alignItems: "center",
    display: "flex",
  }),
  searchGroup: css({
    backgroundColor: "var(--defaultBgColor)",
    bottom: 0,
    height: "100%",
    minHeight: "100vh",
    left: 0,
    overflow: "hidden",
    position: "fixed",
    right: 0,
    top: 0,
    padding: 20,
    transition: "all 0.2s var(--transition)",
    userSelect: "none",
    "@media (min-width: 992px)": {
      display: "none",
    },
  }),
  searchHeader: css({
    alignItems: "center",
    display: "flex",
    padding: 0,
    position: "relative",
    width: "100%",
  }),
  searchCloseButton: css({
    appearance: "none",
    background: "none",
    border: "none",
    borderRadius: 0,
    color: "var(--mutedTextColor)",
    cursor: "pointer",
    display: "block",
    outline: 0,
    padding: 0,
    position: "relative",
    marginLeft: "auto",
    "&:hover": {
      color: "var(--mutedTextColor)",
    },
  }),
  closeIcon: css({
    display: "block",
  }),
  searchField: css({
    display: "block",
    paddingLeft: 15,
    width: "100%",
  }),
  searchResult: css({
    bottom: 0,
    display: "block",
    left: 0,
    position: "absolute",
    right: 0,
    top: 54,
    zIndex: 999,
  }),
  searchResultContainer: css({
    maxHeight: "100%",
  }),
  inputGroup: css({
    width: "calc(100% - 32px)",
  }),
  input: css({
    appearance: "none",
    background: "none",
    backgroundColor: "var(--inputBgColor)",
    border: "1px solid var(--inputBorderColor)",
    borderRadius: 4,
    boxShadow: "none",
    color: "var(--textColor)",
    display: "block",
    fontSize: 16,
    lineHeight: "20px",
    outline: "none",
    padding: "8px 8px 8px 40px",
    transition: "all 0.1s var(--transition)",
    width: "100%",
    "&::placeholder": {
      color: "var(--mutedTextColor)",
      textAlign: "left",
    },
  }),
  searchIcon: css({
    color: "var(--defaultBrandColor)",
    pointerEvents: "none",
    position: "absolute",
    top: 7,
    left: 8,
  }),
  headerSearchListContainer: css({
    marginTop: 20,
    height: "calc(100% - 50px)",
  }),
  headerSearchList: css({
    height: "100%",
    overflow: "auto",
  }),
};
