import { useMemo, useEffect, useState } from "react";
import debounce from "lodash.debounce";

const DEBOUNCE_WAIT_TIME = 500;

type Props = {
  onChangeValue: (value: string) => void;
};

export const useDebounceValue = ({ onChangeValue }: Props) => {
  const [value, setValue] = useState("");
  const debounceChangeValue = useMemo(() => debounce((v) => onChangeValue(v), DEBOUNCE_WAIT_TIME), [onChangeValue]);

  useEffect(() => {
    debounceChangeValue(value);
  }, [debounceChangeValue, value]);

  return { value, setValue };
};
