/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useState, useCallback } from "react";
import { isIOS, isMobile, osName } from "react-device-detect";
import isEmpty from "lodash.isempty";
import delay from "lodash.delay";
import { RouteComponentProps } from "react-router";
import { OutboundLink } from "react-ga";
import copy from "clipboard-copy";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { FiChevronRight, FiCopy, FiPhoneOutgoing } from "react-icons/fi";
import { useCafeQuery, ChargingPermission, WiFi, Smoking, MobileBatteryPermission } from "../graphql/generated/schema";
import { NotFound } from "./NotFound";
import { RelatedCafes } from "../components/RelatedCafes";
import { Layout } from "../components/Layout";
import { GoogleAds } from "../components/GoogleAds";
import { CafeTitle } from "../components/CafeTitle";
import { CafeRow } from "../components/CafeRow";
import { Locales } from "../constants/locales";
import { formUrl } from "../constants/uri";
import { Settings } from "../constants/settings";
import { ExternalLink } from "../components/ExternalLink";
import { PageScheconAds } from "../components/PageScheconAds";

type Props = RouteComponentProps<{ cafeId: string }>;

const enableShareApi = (navigator as any).share !== undefined;

export const Cafe: React.FC<Props> = ({ match, location }) => {
  // Note: idはpathに読み替える
  const cafePath = match.params.cafeId;
  const [tooltipShow, setTooltipState] = useState<boolean>(false);
  const { loading, error, data } = useCafeQuery({
    variables: {
      path: cafePath,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleCopy = useCallback(
    (address: string) => {
      setTooltipState(true);
      copy(address);
      delay(() => setTooltipState(false), 1500);
    },
    [location]
  );

  if (loading) {
    return null;
  }

  if (error) {
    if (error.graphQLErrors[0].extensions.code === "404") {
      return <NotFound />;
    }
    console.error(error);
  }

  if (!data) {
    return null;
  }

  const { cafe } = data;

  if (!cafe) {
    return null;
  }

  const {
    access,
    address1,
    address2,
    businessHours,
    cafeToSponsors,
    cafeToUsages,
    chargingMemo,
    chargingPermission,
    city,
    holiday,
    pictures,
    latitude,
    longitude,
    meal,
    memo,
    mobileBatteryMemo,
    mobileBatteryPermission,
    outletsCount,
    phone,
    prefecture,
    recommendation,
    reviewComment,
    smoking,
    takeAwayAvailable,
    terraceSeatsAvailable,
    url,
    wifi,
    wifiMemo,
    brand,
    branchName,
    description,
  } = cafe;

  const cafeName = `${brand.name} ${branchName ? `${branchName}` : ""}${(description && `【${description}】`) || ""}`;
  const mapAddress = `${prefecture}${city}${address1 || ""}`;
  const displayAddress = `${prefecture}${city}${address1 || ""} ${address2 || ""}`;
  const addressEnc = encodeURI(mapAddress);
  const mapSrc = `https://www.google.com/maps/embed/v1/place?key=${
    Settings.mapKey
  }&q=${""},${addressEnc}&center=${latitude},${longitude}`;
  const title = `${cafeName} | ${Locales.BRAND.en}(${Locales.BRAND.ja})`;
  const metaDescription = `${cafeName}の電源カフェ情報（所在地・電話番号・コンセント有無や設置箇所・WiFi有無やブランド・喫煙禁煙・設備・雰囲気やテイストなど）を紹介しています`;

  return (
    <>
      <Helmet title={title}>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Layout location={location} showHeader showFooter>
        <div css={styles.wrapper}>
          <div css={styles.container}>
            <CafeTitle cafe={cafe} cafeName={cafeName} enableShareApi={enableShareApi} pathname={location.pathname} />
            {!isEmpty(pictures) && pictures.length > 1 && (
              <div css={styles.slider}>
                <Swiper loop={true} navigation={true} modules={[EffectFade, Navigation]} effect="fade">
                  {pictures.map((picture) => {
                    return (
                      <SwiperSlide key={picture.id}>
                        <div css={styles.slideImage} style={{ backgroundImage: `url(${picture.url})` }} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            )}
            {!isEmpty(pictures) && pictures.length === 1 && (
              <div css={styles.slider}>
                {pictures.map((picture) => {
                  return (
                    <div key={picture.id}>
                      <div css={styles.slideImage} style={{ backgroundImage: `url(${picture.url})` }} />
                    </div>
                  );
                })}
              </div>
            )}
            <div css={styles.cafeContentWrapper}>
              <div>
                <section css={styles.cafeContentSection}>
                  <div css={styles.cafeContentSectionInner}>
                    <h2 css={styles.cafeTitle}>{Locales.CAFE_INFOMATION_TITLE}</h2>
                    <div css={styles.cafeContent}>
                      <div css={styles.address}>
                        <div css={styles.addressContainer}>
                          <div css={styles.addressText}>{displayAddress}</div>
                          <button type="button" css={styles.addressButton} onClick={() => handleCopy(displayAddress)}>
                            <FiCopy size={20} />
                            {tooltipShow && (
                              <div css={styles.tooltip}>
                                <div css={styles.tooltipInner}>コピーしました！</div>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                      <div css={styles.tel}>
                        <div css={styles.telContainer}>
                          <div css={styles.telText}>{phone}</div>
                          {isMobile && (
                            <a css={styles.telLink} href={`tel:${phone}`}>
                              <FiPhoneOutgoing size={20} />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section css={styles.cafeContentSection}>
                  <div css={styles.cafeContentSectionInner}>
                    <h2 css={styles.cafeContentTitle}>{Locales.CAFE_DETAILS_TITLE}</h2>
                    <div css={styles.cafeDetails}>
                      <CafeRow title="営業時間">{businessHours}</CafeRow>
                      <CafeRow title="休日">{holiday}</CafeRow>
                      <CafeRow title="アクセス">{access}</CafeRow>
                      <CafeRow title="電源口数">{`${outletsCount}`}</CafeRow>
                      {chargingPermission !== ChargingPermission.Unknown && (
                        <>
                          <CafeRow title="電源利用許可">
                            {chargingPermission === ChargingPermission.Necessary && `必要`}
                            {chargingPermission === ChargingPermission.Unnecessary && `不要`}
                          </CafeRow>
                          {!isEmpty(chargingMemo) && <CafeRow title="電源補足">{chargingMemo}</CafeRow>}
                        </>
                      )}
                      {wifi !== WiFi.Unknown && (
                        <>
                          <CafeRow title="Wi-Fi">
                            {wifi === WiFi.Available && `利用可`}
                            {wifi === WiFi.NotAvailable && `利用不可`}
                          </CafeRow>
                          {!isEmpty(wifiMemo) && <CafeRow title="Wi-Fi補足">{wifiMemo}</CafeRow>}
                        </>
                      )}
                      {smoking !== Smoking.Unknown && (
                        <>
                          <CafeRow title="禁煙・喫煙">
                            {smoking === Smoking.CigaretteAtSeat && `席で喫煙可能（紙巻・電子）`}
                            {smoking === Smoking.CigaretteAtSpace && `喫煙スペース有（紙巻・電子）`}
                            {smoking === Smoking.EcigaretteAtSeat && `席で喫煙可能（電子タバコのみ）`}
                            {smoking === Smoking.EcigaretteAtSpace && `喫煙スペース有（電子タバコのみ）`}
                            {smoking === Smoking.NonSmoking && `完全禁煙`}
                          </CafeRow>
                        </>
                      )}
                      {cafeToSponsors && cafeToSponsors.length > 0 && (
                        <CafeRow title="スポンサー">
                          {cafeToSponsors.map((cafeToSponsor) => {
                            return (
                              <span key={cafeToSponsor?.sponsor?.id} css={styles.sponsor}>
                                {cafeToSponsor?.sponsor?.name}
                              </span>
                            );
                          })}
                        </CafeRow>
                      )}
                      {terraceSeatsAvailable && <CafeRow title="テラス席">有り</CafeRow>}
                      {mobileBatteryPermission !== MobileBatteryPermission.Unknown && (
                        <>
                          <CafeRow title="モバイルバッテリー">
                            {mobileBatteryPermission === MobileBatteryPermission.Available && `利用可`}
                            {mobileBatteryPermission === MobileBatteryPermission.NotAvailable && `利用不可`}
                          </CafeRow>
                          {<CafeRow title="モバイルバッテリー補足">{mobileBatteryMemo}</CafeRow>}
                        </>
                      )}
                      {takeAwayAvailable && <CafeRow title="テイクアウト">有り</CafeRow>}
                      {meal && (
                        <CafeRow title="食事">
                          {meal.morning && <span css={styles.meal}>モーニング</span>}
                          {meal.lunch && <span css={styles.meal}>ランチ</span>}
                          {meal.dinner && <span css={styles.meal}>ディナー</span>}
                          {meal.snack && <span css={styles.meal}>軽食</span>}
                          {meal.dessert && <span css={styles.meal}>デザート</span>}
                          {meal.alcohol && <span css={styles.meal}>お酒</span>}
                        </CafeRow>
                      )}
                      {cafeToUsages && cafeToUsages.length > 0 && (
                        <CafeRow title="用途">
                          {cafeToUsages.map((cafeToUsage) => {
                            return (
                              <span key={cafeToUsage?.usage?.id} css={styles.usage}>
                                {cafeToUsage?.usage?.name}
                              </span>
                            );
                          })}
                        </CafeRow>
                      )}
                      {recommendation && <CafeRow title="おすすめ" content={recommendation} embedHtml />}
                      {memo && <CafeRow title="備考" content={memo} embedHtml />}
                      {reviewComment && <CafeRow title="レビュー" content={reviewComment} embedHtml />}
                      {url && (
                        <CafeRow title="URL">
                          <ExternalLink target="_blank" to={url}>
                            {url}
                          </ExternalLink>
                        </CafeRow>
                      )}
                    </div>
                  </div>
                </section>
              </div>
              {cafe.longitude && cafe.latitude && (
                <div css={styles.relatedCafesWrapper}>
                  <RelatedCafes
                    style={styles.relatedCafes}
                    currentCafe={cafe}
                    longitude={cafe.longitude}
                    latitude={cafe.latitude}
                  />
                </div>
              )}
            </div>
            {latitude && longitude && (
              <div css={styles.cafeMapWrapper}>
                <section css={styles.cafeContentSection}>
                  <h2 css={styles.cafeContentTitle}>{Locales.CAFE_MAP_TITLE}</h2>
                  <div css={styles.cafeMap}>
                    <div css={styles.cafeMapContainer}>
                      <iframe
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        src={mapSrc}
                        title={cafe.name || ""}
                        allowFullScreen
                      />
                    </div>
                  </div>
                  {isMobile && (
                    <div css={styles.cafeMapAction}>
                      <OutboundLink
                        css={styles.cafeMapLink}
                        eventLabel={`RouteSearch_${osName}`}
                        to={
                          isIOS
                            ? `comgooglemaps://?daddr=${latitude},${longitude}&directionsmode=walking`
                            : `https://www.google.co.jp/maps/dir/${longitude},${latitude}`
                        }
                        target="_blank"
                      >
                        {Locales.CAFE.MAP.ACTION}
                      </OutboundLink>
                    </div>
                  )}
                </section>
              </div>
            )}
            <div css={styles.cafeOptionWrapper}>
              <div css={styles.cafeContentSection}>
                <h2 css={styles.cafeOptionTitle}>
                  <ExternalLink target="_blank" to={formUrl.leakForCafe} css={styles.cafeOptionTitleLink}>
                    {Locales.CAFE_LEAK_FORM}
                    <FiChevronRight size={20} />
                  </ExternalLink>
                </h2>
              </div>
            </div>
            <div css={styles.cafeOptionWrapper}>
              <div css={styles.cafeContentSection}>
                <h2 css={styles.cafeOptionTitle}>
                  <ExternalLink target="_blank" to={formUrl.leakForFix} css={styles.cafeOptionTitleLink}>
                    {Locales.CAFE_CORRECT_REQUEST}
                    <FiChevronRight size={20} />
                  </ExternalLink>
                </h2>
              </div>
            </div>
            <div css={styles.adsContainer}>
              <GoogleAds slot="1089938436" format="auto" responsive="true" />
            </div>
          </div>
        </div>
        <PageScheconAds />
      </Layout>
    </>
  );
};

const styles = {
  wrapper: css({
    position: "relative",
  }),
  relatedCafesWrapper: css({
    "@media (max-width: 991px)": {
      display: "none",
    },
  }),
  container: css({
    marginLeft: "auto",
    marginRight: "auto",
    "@media (min-width: 992px)": {
      maxWidth: 1120,
      paddingLeft: 20,
      paddingRight: 20,
      position: "relative",
    },
  }),
  slider: css({
    marginTop: 20,
    position: "relative",
    width: "100%",
    ".swiper-button-next": css({
      color: "var(--textColor)",
      right: 0,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      borderRadius: "4px 0 0 4px",
      "&:after": css({
        fontSize: 16,
        lineHeight: "44px",
      }),
    }),
    ".swiper-button-prev": css({
      color: "var(--textColor)",
      left: 0,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      borderRadius: "0 4px 4px 0",
      "&:after": css({
        fontSize: 16,
        lineHeight: "44px",
      }),
    }),
  }),
  slideImage: css({
    backgroundColor: "transparent",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "@media (max-width: 991px)": {
      paddingBottom: "56.25%",
      height: "initial",
    },
    "@media (min-width: 992px)": {
      height: 400,
    },
  }),
  cafeContentWrapper: css({
    marginBottom: 20,
    position: "relative",
  }),
  cafeContentSection: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    "@media (min-width: 992px)": {
      marginTop: 20,
      marginBottom: 20,
      paddingTop: 20,
      paddingBottom: 20,
    },
  }),
  cafeContentSectionInner: css({
    "@media (min-width: 992px)": {
      maxWidth: "calc(100% - 400px)",
    },
  }),
  cafeTitle: css({
    fontWeight: 700,
    lineHeight: 1,
    margin: 0,
    "@media (max-width: 991px)": {
      borderBottom: "1px solid var(--borderColor)",
      fontSize: 14,
      padding: 15,
    },
    "@media (min-width: 992px)": {
      borderLeft: "5px solid var(--defaultBrandColor)",
      fontSize: 16,
      marginBottom: 20,
      padding: "5px 15px",
    },
  }),
  cafeContent: css({
    "@media (max-width: 991px)": {
      padding: 15,
    },
    "@media (min-width: 992px)": {
      paddingLeft: 20,
      paddingRight: 20,
    },
  }),
  address: css({
    "@media (max-width: 991px)": {
      marginBottom: 20,
    },
    "@media (min-width: 992px)": {
      paddingRight: 20,
    },
  }),
  addressContainer: css({
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    "@media (min-width: 992px)": {
      paddingBottom: 10,
      paddingTop: 10,
    },
  }),
  addressText: css({
    color: "var(--textColor)",
    fontSize: 14,
    lineHeight: "20px",
    overflow: "hidden",
    padding: 0,
    paddingRight: 10,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "calc(100% - 48px)",
  }),
  addressButton: css({
    appearance: "none",
    background: "none",
    border: "none",
    borderRadius: 3,
    color: "var(--defaultBrandColor)",
    cursor: "pointer",
    marginLeft: "auto",
    outline: 0,
    padding: 0,
    position: "relative",
    textAlign: "center",
    transition: "all 0.15s var(--transition)",
    svg: css({
      display: "block",
      color: "var(--defaultBrandColor)",
    }),
  }),
  tooltip: css({
    marginLeft: -15,
    position: "absolute",
    top: -25,
    right: -30,
    transition: "all 0.2s var(--transition)",
    whiteSpace: "nowrap",
  }),
  tooltipInner: css({
    backgroundColor: "var(--successColor)",
    borderRadius: 3,
    boxShadow: "var(--boxShadow)",
    color: "var(--whiteColor)",
    fontSize: 12,
    lineHeight: 1,
    padding: "6px 8px",
    textAlign: "center",
    position: "relative",
  }),
  tel: css({
    margin: 0,
  }),
  telContainer: css({
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    "@media (min-width: 992px)": {
      paddingBottom: 10,
      paddingTop: 10,
    },
  }),
  telText: css({
    color: "var(--textColor)",
    fontSize: 14,
    lineHeight: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "calc(100% - 20px)",
  }),
  telLink: css({
    color: "var(--defaultBrandColor)",
    marginLeft: "auto",
    position: "relative",
    textAlign: "center",
    lineHeight: "20px",
    transition: "all 0.15s var(--transition)",
    svg: css({
      display: "block",
    }),
  }),
  tagList: css({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "@media (min-width: 992px)": {
      paddingTop: 10,
    },
  }),
  cafeContentTitle: css({
    fontWeight: 700,
    lineHeight: 1,
    margin: 0,
    "@media (max-width: 991px)": {
      borderBottom: "1px solid var(--borderColor)",
      borderTop: "1px solid var(--borderColor)",
      fontSize: 14,
      padding: 15,
    },
    "@media (min-width: 992px)": {
      borderLeft: "5px solid var(--defaultBrandColor)",
      fontSize: 16,
      marginBottom: 20,
      padding: "5px 15px",
    },
  }),
  cafeDetails: css({
    position: "relative",
    "@media (min-width: 992px)": {
      paddingLeft: 20,
      paddingRight: 20,
    },
  }),
  relatedCafes: css({
    width: 400,
    "@media (min-width: 992px)": {
      paddingBotom: 20,
      paddingRight: 20,
      paddintTop: 20,
      position: "absolute",
      right: 20,
      top: 0,
    },
  }),
  cafeMapWrapper: css({
    marginBottom: 20,
    position: "relative",
    "@media (max-width: 991px)": {
      backgroundColor: "var(--defaultComponentBgColor)",
    },
  }),
  cafeMap: css({
    paddingTop: "62.5%",
    position: "relative",
    width: "100%",
  }),
  cafeMapContainer: css({
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  }),
  cafeMapAction: css({
    borderTop: "1px solid var(--borderColor)",
    padding: "20px 15px",
  }),
  cafeMapLink: css({
    borderRadius: 3,
    boxShadow: "inset 0 0 0 1px var(--borderColor)",
    color: "var(--textColor)",
    display: "block",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "20px",
    paddingBottom: 12,
    paddingTop: 12,
    textAlign: "center",
    "&:hover": {
      backgroundColor: "var(--defaultBrandColor)",
      boxShadow: "inset 0 0 0 1px var(--defaultBrandColor)",
      color: "var(--textColor)",
      fontWeight: 700,
    },
  }),
  cafeOptionWrapper: css({
    marginBottom: 20,
    position: "relative",
  }),
  cafeOptionTitle: css({
    fontWeight: 700,
    lineHeight: 1,
    margin: 0,
    "@media (max-width: 991px)": {
      borderBottom: "1px solid var(--borderColor)",
      borderTop: "1px solid var(--borderColor)",
      fontSize: 14,
      padding: 15,
    },
    "@media (min-width: 992px)": {
      borderLeft: "5px solid var(--defaultBrandColor)",
      fontSize: 16,
      margin: 0,
      padding: "5px 15px",
    },
  }),
  cafeOptionTitleLink: css({
    alignItems: "flex-start",
    color: "var(--textColor)",
    display: "flex",
    flexWrap: "wrap",
    lineHeight: "20px",
    "&:hover": {
      color: "var(--defaultBrandColor)",
    },
    svg: css({
      display: "block",
      marginLeft: "auto",
    }),
    "@media (max-width: 991px)": {
      padding: "12px 15px",
      fontSize: 14,
    },
    "@media (min-width: 992px)": {
      fontSize: 16,
    },
  }),
  adsContainer: css({
    "@media (max-width: 991px)": {
      padding: "0 15px",
    },
    "@media (min-width: 992px)": {
      margin: "30px auto",
      maxWidth: 970,
    },
  }),
  meal: css({
    "& + &": css({
      "&:before": {
        content: '","',
        marginLeft: 4,
        marginRight: 4,
      },
    }),
  }),
  usage: css({
    "& + &": css({
      "&:before": {
        content: '","',
        marginLeft: 4,
        marginRight: 4,
      },
    }),
  }),
  sponsor: css({
    "& + &": css({
      "&:before": {
        content: '","',
        marginLeft: 4,
        marginRight: 4,
      },
    }),
  }),
};
