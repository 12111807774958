import { createContext, ReactChild, useContext, useReducer } from "react";

type State = {
  theme: string;
};

export enum Themes {
  LIGHT = "light",
  DARK = "dark",
}

const initialState = {
  theme: window.localStorage.getItem(`theme`) || Themes.LIGHT,
};
const ThemeContext = createContext({});

const themeReducer = (state: State, action) => {
  switch (action.type) {
    case Themes.LIGHT:
      return {
        ...state,
        theme: Themes.LIGHT,
      };
    case Themes.DARK:
      return {
        ...state,
        theme: Themes.DARK,
      };
    default:
      return state;
  }
};

export const ThemeContextProvider = ({ children }: { children: ReactChild }): any => {
  const [state, dispatch] = useReducer(themeReducer, initialState);
  return <ThemeContext.Provider value={{ state, dispatch }}>{children}</ThemeContext.Provider>;
};

export const useTheme = (): any => {
  return useContext(ThemeContext);
};

export const updateThemeDataset = (theme: string) => {
  const htmlTag = document.querySelector("html");

  if (!htmlTag) return false;

  if (theme === Themes.DARK) {
    window.localStorage.setItem("theme", Themes.DARK);
    htmlTag.dataset.theme = "dark";
    return true;
  }

  window.localStorage.setItem("theme", Themes.LIGHT);
  htmlTag.dataset.theme = "";
  return true;
};
