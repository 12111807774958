import { Locales } from "./locales";
import { uri } from "./uri";
import ogp from "../assets/images/cafe.png";

export const Helmets = {
  meta: [
    { name: "description", content: Locales.HEADER.DESCRIPTION },
    { name: "msapplication-TileColor", content: "#ffffff" },
    { name: "msapplication-TileImage", content: "/ms-icon-144x144.png" },
    { name: "theme-color", content: "#ffffff" },
    { property: "og:title", content: Locales.HEADER.TITLE },
    { property: "og:description", content: Locales.HEADER.DESCRIPTION },
    { property: "og:url", content: uri.dengencafe },
    { property: "og:image", content: `${uri.dengencafe}${ogp}` },
    { property: "fb:app_id", content: "444458442277643" },
  ],
  link: [
    { rel: "icon", type: "image/png", href: "/assets/favicon/favicon.ico" },
    { rel: "apple-touch-icon", sizes: "57x57", href: "/assets/favicon/apple-icon-57x57.png" },
    { rel: "apple-touch-icon", sizes: "72x72", href: "/assets/favicon/apple-icon-72x72.png" },
    { rel: "apple-touch-icon", sizes: "76x76", href: "/assets/favicon/apple-icon-76x76.png" },
    { rel: "apple-touch-icon", sizes: "114x114", href: "/assets/favicon/apple-icon-114x114.png" },
    { rel: "apple-touch-icon", sizes: "120x120", href: "/assets/favicon/apple-icon-120x120.png" },
    { rel: "apple-touch-icon", sizes: "144x144", href: "/assets/favicon/apple-icon-144x144.png" },
    { rel: "apple-touch-icon", sizes: "152x152", href: "/assets/favicon/apple-icon-152x152.png" },
    { rel: "apple-touch-icon", sizes: "180x180", href: "/assets/favicon/apple-icon-180x180.png" },
    { rel: "icon", type: "image/png", sizes: "192x192", href: "/assets/favicon/android-icon-192x192.png" },
    { rel: "icon", type: "image/png", sizes: "32x32", href: "/assets/favicon/favicon-32x32.png" },
    { rel: "icon", type: "image/png", sizes: "96x96", href: "/assets/favicon/favicon-96x96.png" },
    { rel: "icon", type: "image/png", sizes: "16x16", href: "/assets/favicon/favicon-16x16.png" },
    { rel: "manifest", href: "/manifest.json" },
  ],
};
