/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import { FC, useCallback, useState } from "react";
import { useTheme, Themes, updateThemeDataset } from "../contexts/ThemeContextProvider";

type Props = {
  style?: SerializedStyles;
};

const styles = {
  switch: css({
    display: "flex",
    alignItems: "center",
  }),
  switchText: css({
    color: "var(--textColor)",
    fontSize: 10,
    fontWeight: 500,
    marginRight: 8,
  }),
  indicator: css({
    alignItems: "center",
    background: "none",
    border: "none",
    borderRadius: 30,
    boxShadow: "none",
    cursor: "pointer",
    display: "flex",
    height: 30,
    outline: 0,
    padding: 0,
    position: "relative",
    transition: "background-color 0.25s var(--transition)",
    width: 48,
  }),
  indicatorOn: css({
    backgroundColor: "var(--blueColor)",
  }),
  indicatorOff: css({
    backgroundColor: "var(--grayLight)",
  }),
  indicatorButton: css({
    background: "var(--whiteColor)",
    borderRadius: "50%",
    display: "block",
    height: 26,
    width: 26,
    margin: 2,
    position: "absolute",
    top: 0,
    transition: "transform 0.25s var(--transition)",
  }),
  indicatorButtonOn: css({
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.2)",
    transform: "translate3d(18px, 0, 0)",
  }),
  indicatorButtonOff: css({
    transform: "translate3d(0, 0, 0)",
  }),
};

export const ThemeSwitch: FC<Props> = ({ style }) => {
  const themeContext = useTheme();
  const [theme, setTheme] = useState<string>(themeContext.state.theme);
  const isDarkTheme = theme === Themes.DARK;

  const handleChangeTheme = useCallback(() => {
    const newTheme = isDarkTheme ? Themes.LIGHT : Themes.DARK;

    setTheme(newTheme);
    themeContext.dispatch({ theme: newTheme });
    updateThemeDataset(newTheme);
  }, [theme, setTheme]);

  return (
    <div css={style}>
      <div css={styles.switch}>
        <span css={styles.switchText}>ダークモード</span>
        <button
          type="button"
          css={[styles.indicator, isDarkTheme ? styles.indicatorOn : styles.indicatorOff]}
          onClick={handleChangeTheme}
        >
          <span css={[styles.indicatorButton, isDarkTheme ? styles.indicatorButtonOn : styles.indicatorButtonOff]} />
        </button>
      </div>
    </div>
  );
};
