/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import { useEffect } from "react";

declare const window: any;

type Props = {
  format?: string;
  layout?: string;
  layoutKey?: string;
  responsive?: string;
  slot: string;
  style?: SerializedStyles;
  disabled?: boolean;
};

const isProductionEnv = process.env.REACT_APP_ENV === "production";

const styles = {
  wrapper: css({
    marginBottom: 20,
    position: "relative",
  }),
};

export const GoogleAds: React.FC<Props> = ({ format, layout, layoutKey, responsive, slot, style, disabled }) => {
  useEffect(() => {
    if (isProductionEnv && window) {
      window.adsbygoogle = window.adsbygoogle || [];
      window.adsbygoogle.push({});
    }
  }, [disabled]);

  if (!isProductionEnv) return null;
  if (disabled) return null;

  return (
    <div css={styles.wrapper}>
      <ins
        css={style}
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-6586215673960749"
        data-ad-slot={slot}
        data-ad-layout={layout}
        data-ad-layout-key={layoutKey}
        data-ad-format={format}
        data-full-width-responsive={responsive}
      />
    </div>
  );
};
