/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HomeArticleCard } from "./HomeArticleCard";
import { Routes } from "../constants/routes";
import { Locales } from "../constants/locales";
import { microCmsClient, ArticleResult } from "../utils/microCmsClient";

export const HomeArticles: React.FC = () => {
  const [articles, setArticles] = useState<ArticleResult[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const result = await microCmsClient.getList({
          endpoint: "articles",
          queries: { limit: 3 },
        });
        setArticles(result.contents);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div css={styles.wrapper}>
      <section css={styles.section}>
        <div css={styles.header}>
          <h1 css={styles.title}>{Locales.LATEST_ARTICLES}</h1>
          <Link css={styles.link} to={`/${Routes.ARTICLES}`}>
            {Locales.LOAD_MORE}
          </Link>
        </div>
        <div css={styles.cards}>
          {articles?.map((article) => (
            <HomeArticleCard key={article.id} article={article} />
          ))}
        </div>
      </section>
    </div>
  );
};

const styles = {
  wrapper: css({
    marginLeft: "auto",
    marginRight: "auto",
    "@media (max-width: 991px)": {
      paddingLeft: 15,
      paddingRight: 15,
    },
    "@media (min-width: 992px)": {
      paddingLeft: 20,
      paddingRight: 20,
      maxWidth: 1120,
    },
  }),
  section: css({
    "@media (max-width: 991px)": {
      margin: "0 auto",
      padding: "32px 0 24px",
    },
    "@media (min-width: 992px)": {
      margin: "40px auto 0",
      padding: "0 15px 40px",
    },
  }),
  header: css({
    display: "flex",
  }),
  title: css({
    color: "var(--textColor)",
    fontWeight: 700,
    boxShadow: "inset 5px 0 0 var(--defaultBrandColor)",
    margin: 0,
    paddingLeft: 15,
    fontSize: 20,
  }),
  link: css({
    marginLeft: "auto",
    fontSize: 14,
    color: "var(--subTextColor)",
    "&:hover": {
      color: "var(--defaultBrandColor)",
      textDecoration: "underline",
    },
  }),
  cards: css({
    position: "relative",
    display: "block",
    "@media (min-width: 767px)": {
      display: "flex",
      alignItems: "stretch",
      flexWrap: "wrap",
      marginRight: -8,
      marginLeft: -8,
    },
  }),
};
