export const Routes = {
  ARTICLES: `articles`,
  ARCHIVES: `archives`,
  CAFES: `cafes`,
  FORM: `articles/31917`,
  ABOUT: `about`,
  MEDIA: `articles/31913`,
  COMPANY: `company`,
  TERMS: `terms`,
  RELEASE: `release`,
  PRIVACY: `articles/31665`,
  SEARCH: `search`,
  TAGS: `tags`,
};
