import React from "react";
import { v4 as uuidv4 } from "uuid";

const id = uuidv4();
export const Script: React.FC<{ src: string; async?: boolean }> = ({ src, async }) => {
  React.useEffect(() => {
    const script = document.createElement("script");

    script.src = src;
    script.async = async || false;

    const tag = document.getElementById(id);

    if (tag !== null) tag.replaceWith(script);
  });

  return <div id={id} />;
};
