/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import { FC } from "react";

type Props = {
  children?: React.ReactNode;
  style?: SerializedStyles;
  baseStyle?: boolean;
  withPadding?: boolean;
};

const styles = {
  wrapper: (baseStyle) =>
    css(
      {
        position: "relative",
      },
      baseStyle && {
        "@media screen and (min-width: 992px)": {
          flex: "1 0 calc(100% - 300px)",
          width: "calc(100% - 300px)",
        },
      }
    ),
};

export const MainPane: FC<Props> = ({ baseStyle = true, children, style }) => {
  if (!children) {
    return null;
  }

  return <div css={[styles.wrapper(baseStyle), style]}>{children}</div>;
};
