import { render } from "react-dom";
import { App } from "./App";
import { unregister } from "./serviceWorker";
import { ContextProvider } from "./contexts/ContextProvider";
import "./stylesheets/globalStyle";

render(
  <ContextProvider>
    <App />
  </ContextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
