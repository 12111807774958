import React from "react";
import { RouteComponentProps } from "react-router";
import { css } from "@emotion/react";
import { Layout } from "../components/Layout";
import { HomeEyecatch } from "../components/HomeEyecatch";
import { HomeCafes } from "../components/HomeCafes";
import { HomeAds } from "../components/HomeAds";
import { HomeArticles } from "../components/HomeArticles";
import { HomeScheconAds } from "../components/HomeScheconAds";

type Props = RouteComponentProps;

const styles = {
  wrapper: css({
    backgroundColor: "var(--defaultBgColor2)",
  }),
};

export const Home: React.FC<Props> = ({ location }) => (
  <Layout location={location} showHeader showFooter style={styles.wrapper}>
    <HomeEyecatch />
    <HomeCafes />
    <HomeArticles />
    <HomeScheconAds />
    <HomeAds />
  </Layout>
);
