/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import isEmpty from "lodash.isempty";
import { Link } from "react-router-dom";
import { CafePage_CafeFragment } from "../graphql/generated/schema";
import { Routes } from "../constants/routes";

type Props = {
  node: CafePage_CafeFragment;
};

export const MapPop: React.FC<Props> = ({ node }) => {
  const { access, path, pictures, name } = node;

  return (
    <div css={styles.wrapper}>
      <Link css={styles.link} to={`/${Routes.CAFES}/${path}`}>
        {!isEmpty(pictures) && (
          <div css={styles.imageContainer}>
            <div css={styles.image} style={{ backgroundImage: `url(${pictures[0].url})` }} />
          </div>
        )}
        <div css={styles.cafe}>
          <div css={styles.name}>{name}</div>
          <div css={styles.access}>{access}</div>
        </div>
      </Link>
    </div>
  );
};

const styles = {
  wrapper: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    margin: 0,
    position: "relative",
  }),
  link: css({
    display: "block",
  }),
  imageContainer: css({
    backgroundColor: "var(--lighterTextColor)",
    height: 150,
    position: "relative",
    width: "100%",
  }),
  image: css({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  }),
  cafe: css({
    padding: 0,
  }),
  name: css({
    color: "var(--textColor)",
    fontSize: 13,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 0,
  }),
  access: css({
    color: "var(--mutedTextColor)",
    fontSize: 12,
    marginTop: 10,
    marginBottom: 0,
  }),
};
