/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import ScheconPng from "../assets/images/schecon/210628.jpg";
import { ExternalLink } from "../components/ExternalLink";

export const HomeScheconAds: React.FC = () => {
  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        <ExternalLink target="_blank" to="https://schecon.com/" css={styles.bannerLink}>
          <img src={ScheconPng} alt="schecon" />
        </ExternalLink>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    "@media (min-width: 992px)": {
      display: "none",
    },
  }),
  container: css({
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
    marginBottom: 30,
    paddingLeft: 15,
    paddingRight: 15,
  }),
  bannerLink: css({
    display: "flex",
    width: "100%",
    justifyContent: "center",
  }),
};
