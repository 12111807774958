/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import { FC } from "react";
import isEmpty from "lodash.isempty";
import { Link } from "react-router-dom";
import { useRelatedCafesQuery, CafePage_CafeFragment } from "../graphql/generated/schema";
import { ReadMoreLink } from "../components/ReadMoreLink";
import { Routes } from "../constants/routes";
import { Locales } from "../constants/locales";
import NoImage from "../assets/images/no_image.jpg";

type Props = {
  style?: SerializedStyles;
  currentCafe: CafePage_CafeFragment;
  latitude: number;
  longitude: number;
};

export const RelatedCafes: FC<Props> = ({ currentCafe, latitude, longitude, style }) => {
  const { id, name } = currentCafe;
  const { loading, error, data } = useRelatedCafesQuery({
    variables: {
      latitude: latitude,
      longitude: longitude,
    },
  });

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
  }

  return (
    <div css={[styles.wrapper, style]}>
      <div css={styles.relatedCafes}>
        <h2 css={styles.title}>{Locales.CAFE_RELATED_CAFES_TITLE}</h2>
        {data?.newCafes?.edges
          .filter((edge) => edge.node.id !== id)
          .slice(0, 2)
          .map((edge) => {
            const { brand, branchName, description, access, businessHours, path, id, pictures } = edge.node;
            const cafeName = `${brand.name} ${branchName || ""}${(description && `【${description}】`) || ""}`;

            return (
              <div key={id} css={styles.card}>
                <Link css={styles.link} to={`/${Routes.CAFES}/${path}`}>
                  <div css={styles.imageContainer}>
                    <div
                      css={styles.image}
                      style={{ backgroundImage: `url(${(!isEmpty(pictures) && pictures[0].url) || NoImage})` }}
                    />
                  </div>
                  <div css={styles.cafe}>
                    <h3 css={styles.name}>{cafeName}</h3>
                    {!isEmpty(businessHours) && <p css={styles.businessHours}>{businessHours}</p>}
                    {!isEmpty(access) && <p css={styles.access}>{access}</p>}
                  </div>
                </Link>
              </div>
            );
          })}
        <ReadMoreLink path={`/${Routes.SEARCH}?lat=${latitude}&lng=${longitude}&name=${name}`} />
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    paddingBottom: 20,
    paddingRight: 20,
    paddingTop: 20,
    position: "relative",
    right: 20,
    top: 0,
  }),
  relatedCafes: css({
    backgroundColor: "var(--relatedCafesBgColor)",
    borderRadius: 6,
    boxShadow: "var(--boxShadow)",
    padding: 24,
    position: "relative",
    width: "100%",
  }),
  title: css({
    borderLeft: "5px solid var(--defaultBrandColor)",
    fontSize: 15,
    margin: "0 0 20px",
    overflow: "hidden",
    padding: "5px 0 5px 10px",
  }),
  card: css({
    border: "1px solid var(--borderColor)",
    margin: "0 0 20px",
    overflow: "hidden",
    "@media screen and (max-width: 991px)": {
      boxShadow: "var(--boxShadow)",
      padding: "10px 15px",
    },
  }),
  link: css({
    display: "block",
  }),
  imageContainer: css({
    backgroundColor: "var(--lighterTextColor)",
    height: 185,
    position: "relative",
    width: "100%",
  }),
  image: css({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  }),
  cafe: css({
    padding: 15,
  }),
  name: css({
    color: "var(--textColor)",
    fontSize: 13,
    fontWeight: 700,
    margin: 0,
  }),
  businessHours: css({
    color: "var(--textColor)",
    fontSize: 12,
    margin: "8px 0 0",
  }),
  access: css({
    color: "var(--textColor)",
    fontSize: 12,
    margin: "0",
  }),
};
