/** @jsxImportSource @emotion/react */

import * as H from "history";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import { FC, useEffect, useState } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";

type Props = {
  baseStyle?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: SerializedStyles;
  location?: H.Location;
  showFooter: boolean;
  showHeader: boolean;
  withPadding?: boolean;
};

const styles = {
  wrapper: css({
    position: "relative",
  }),
};

export const Layout: FC<Props> = ({ style, children, location, showFooter = true, showHeader = true }) => {
  const [enableFooter, setShowFooterState] = useState<boolean>(showFooter);
  const [enableHeader, setShowHeaderState] = useState<boolean>(showHeader);

  useEffect(() => {
    if (showFooter) {
      setShowFooterState(showFooter);
    }
    if (showHeader) {
      setShowHeaderState(showHeader);
    }
  }, [showFooter, showHeader]);

  return (
    <div css={[styles.wrapper, style]}>
      {enableHeader && <Header location={location} />}
      {children}
      {enableFooter && <Footer />}
    </div>
  );
};
