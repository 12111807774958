/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import isEmpty from "lodash.isempty";
import AltImage from "../assets/images/article.png";

export const ArticleHeaderTitle: React.FC<{
  title: string;
  image: string;
}> = ({ title, image }) => (
  <div css={styles.wrapper}>
    <div
      css={styles.bgImageContainer}
      style={isEmpty(image) ? { backgroundImage: `url(${AltImage})` } : { backgroundImage: `url(${image})` }}
    >
      <div css={styles.bgImage} />
    </div>
    <div css={styles.titleContainer}>
      <div css={styles.container}>
        <h1 css={styles.title}>{title}</h1>
      </div>
    </div>
  </div>
);

const styles = {
  wrapper: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    height: 275,
    position: "relative",
  }),
  bgImageContainer: css({
    backgroundColor: "var(--lighterTextColor)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  }),
  bgImage: css({
    backgroundImage: "linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.8))",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  }),
  container: css({
    alignItems: "flex-start",
    display: "flex",
    margin: "0 auto 20px",
    padding: "0 15px",
    "@media (max-width: 767px)": {
      maxWidth: 768,
    },
    "@media (min-width: 992px)": {
      maxWidth: 1120,
      alignItems: "center",
    },
    "@media (min-width: 1280px)": {
      width: 1120,
    },
  }),
  titleContainer: css({
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
  }),
  title: css({
    color: "var(--whiteColor)",
    margin: 0,
    position: "relative",
    "@media screen and (max-width: 991px)": {
      fontSize: 17,
    },
    "@media screen and (min-width: 992px)": {
      fontSize: 24,
    },
  }),
};
