/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FC } from "react";
import dayjs from "dayjs";

export const ArticleHeaderDescription: FC<{
  description: string;
  createdAt: string;
}> = ({ description, createdAt }) => (
  <div css={styles.wrapper}>
    <div css={styles.container}>
      {description && <p css={styles.description}>{description}</p>}
      <div css={styles.dateContainer}>
        <div css={styles.createdAt}>作成日: {dayjs(createdAt).format("YYYY年MM月DD日")}</div>
      </div>
    </div>
  </div>
);

const styles = {
  wrapper: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    padding: 15,
    position: "relative",
  }),
  container: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    margin: "0 auto",
    "@media (max-width: 767px)": {
      maxWidth: 768,
    },
    "@media (min-width: 992px)": {
      maxWidth: 1120,
      alignItems: "stretch",
      display: "flex",
    },
    "@media (min-width: 1280px)": {
      width: 1120,
    },
  }),
  description: css({
    color: "var(--subTextColor)",
    fontSize: 14,
    margin: "0 0 10px",
  }),
  dateContainer: css({}),
  createdAt: css({
    color: "var(--mutedTextColor)",
    fontSize: 14,
  }),
};
