import { useEffect } from "react";
import { BrowserRouter, Route, useLocation, Redirect, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTheme, updateThemeDataset } from "./contexts/ThemeContextProvider";
import { Helmets } from "./constants/helmets";
import { Routes } from "./constants/routes";
import { Locales } from "./constants/locales";
import { usePageViews } from "./utils/usePageViews";
import { Home } from "./pages/Home";
import { Articles } from "./pages/Articles";
import { Article } from "./pages/Article";
import { Tags } from "./pages/Tags";
import { Cafes } from "./pages/Cafes";
import { Cafe } from "./pages/Cafe";
import { Search } from "./pages/Search";

const isProductionReactAppEnv = process.env.REACT_APP_ENV === "production";

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const RootSwitch: React.FC = () => {
  usePageViews();

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path={`/${Routes.CAFES}`} component={Cafes} />
      <Route path={`/${Routes.CAFES}/:cafeId`} component={Cafe} />
      <Route exact path={`/${Routes.ARTICLES}`} component={Articles} />
      <Route path={`/${Routes.SEARCH}`} component={Search} />
      <Route path={`/${Routes.ARTICLES}/:articleId`} component={Article} />
      <Route path={`/${Routes.TAGS}/:slug`} component={Tags} />
      <Redirect from={`/${Routes.ARCHIVES}/(.*)/:slug`} to={`/${Routes.TAGS}/:slug`} />
      <Redirect from={`/${Routes.ARCHIVES}/:cafeId`} to={`/${Routes.CAFES}/:cafeId`} />
    </Switch>
  );
};

export const App: React.FC = () => {
  const themeContext = useTheme();
  const meta = (isProductionReactAppEnv && Helmets.meta) || [...Helmets.meta, { name: `robots`, content: `noindex` }];

  useEffect(() => {
    updateThemeDataset(themeContext.state.theme);
  }, []);

  return (
    <>
      <Helmet title={Locales.HEADER.TITLE} link={Helmets.link} meta={meta} />
      <BrowserRouter>
        <ScrollToTop />
        <RootSwitch />
      </BrowserRouter>
    </>
  );
};
