/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FC, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Helmet } from "react-helmet";
import ContentLoader from "react-content-loader";
import { useTagsIndexQuery, TagsIndexQueryHookResult } from "../graphql/generated/schema";
import { ReadMoreTextButton } from "../components/ReadMoreTextButton";
import { Layout } from "../components/Layout";
import { MainPane } from "../components/MainPane";
import { TagsCafeCards } from "../components/TagsCafeCards";
import { Locales } from "../constants/locales";
import { PageScheconAds } from "../components/PageScheconAds";
import { NotFound } from "./NotFound";

type Props = RouteComponentProps<{ slug: string }>;
type Result = TagsIndexQueryHookResult & {
  fetchMore: any;
};

export const Tags: FC<Props> = ({ match, location }) => {
  const [isFetchMoreLoading, setFetchMoreLoading] = useState<boolean>(false);
  const { error, data, loading, fetchMore }: Result = useTagsIndexQuery({
    variables: {
      cafeTagSlug: match.params.slug,
    },
    onCompleted: () => {
      setFetchMoreLoading(false);
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
  }

  if (data?.cafeTag.archived) {
    return <NotFound />;
  }

  const handleFetchMoreClick = async () => {
    if (data?.cafes?.pageInfo.hasNextPage) {
      setFetchMoreLoading(true);

      await fetchMore({
        variables: { after: data?.cafes?.pageInfo.endCursor },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const result = { ...fetchMoreResult };
          result.cafes.edges = [...prevResult.cafes.edges, ...fetchMoreResult.cafes.edges];
          return result;
        },
      });
    }
  };

  const title = `${data?.cafeTag?.name} | ${Locales.BRAND.en} - ${Locales.BRAND.ja}`;
  const summary = `${data?.cafes?.totalCount}件中 1 - ${data?.cafes?.edges?.length}件`;
  const renderContentLoader = [...Array(9)].map((_, i) => {
    const loaderUniqueKey = `cafeItem_${i}`;
    return (
      <div key={loaderUniqueKey} css={styles.card}>
        <ContentLoader style={{ width: "100%" }} width={317} height={205} uniqueKey={loaderUniqueKey}>
          <>
            <rect x="0" y="0" rx="4" ry="4" width="317" height="150" />
            <rect x="0" y="160" rx="4" ry="4" width="317" height="20" />
            <rect x="0" y="190" rx="4" ry="4" width="317" height="15" />
          </>
        </ContentLoader>
      </div>
    );
  });

  return (
    <>
      <Helmet title={title}>
        <meta property="og:title" content={title} />
        <meta property="og:description" content="" />
        <meta name="description" content="" />
      </Helmet>
      <Layout style={styles.layout} location={location} showHeader showFooter={false}>
        <div css={styles.container}>
          <MainPane baseStyle={false} style={styles.mainPane}>
            <div css={styles.archive}>
              <div css={styles.header}>
                <h2 css={styles.title}>
                  {loading ? (
                    <ContentLoader style={{ width: "100%" }} width={200} height={17} uniqueKey="term">
                      <rect x="0" y="0" rx="4" ry="4" width="200" height="17" />
                    </ContentLoader>
                  ) : (
                    data?.cafeTag?.name
                  )}
                </h2>
                <p css={styles.summary}>
                  {loading ? (
                    <ContentLoader style={{ width: "100%" }} width={300} height={13} uniqueKey="cafeSummary">
                      <rect x="0" y="0" rx="4" ry="4" width="300" height="13" />
                    </ContentLoader>
                  ) : data?.cafes?.totalCount === 0 ? (
                    `該当のカフェがありません`
                  ) : (
                    `${summary}を表示しています`
                  )}
                </p>
              </div>
              <div css={styles.cafes}>
                {loading ? (
                  <div css={styles.cafesCotainer}>{renderContentLoader}</div>
                ) : (
                  <TagsCafeCards cafes={data?.cafes?.edges || []} />
                )}
              </div>
              {data?.cafes?.pageInfo.hasNextPage && (
                <div css={styles.readMore}>
                  <ReadMoreTextButton loading={isFetchMoreLoading} onClick={() => handleFetchMoreClick()} />
                </div>
              )}
            </div>
            <PageScheconAds />
          </MainPane>
        </div>
      </Layout>
    </>
  );
};

const styles = {
  wrapper: css({
    marginLeft: "auto",
    marginRight: "auto",
  }),
  layout: css({
    "@media screen and (min-width: 992px)": {
      overflow: "hidden",
    },
  }),
  container: css({
    "@media screen and (min-width: 992px)": {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
  }),
  mainPane: css({
    paddingBottom: 20,
    paddingTop: 20,
    overflowY: "auto",
    "@media screen and (min-width: 992px)": {
      paddingLeft: 15,
      paddingRight: 15,
      width: "100%",
    },
  }),
  card: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
    borderRadius: 6,
    marginTop: 24,
    padding: "10px 15px",
    "@media screen and (max-width: 991px)": {
      margin: "0 0 20px",
    },
    "@media screen and (min-width: 992px)": {
      marginRight: 16,
      overflow: "hidden",
      width: "calc(33.3333% - 20px)",
    },
    "@media screen and (min-width: 1280px)": {
      width: "calc(25% - 20px)",
    },
  }),
  archive: css({
    position: "relative",
  }),
  header: css({}),
  title: css({
    color: "var(--textColor)",
    margin: 0,
    fontSize: 18,
  }),
  summary: css({
    color: "var(--subTextColor)",
    marginTop: 4,
    marginBottom: 0,
    fontSize: 12,
  }),
  cafes: css({
    position: "relative",
  }),
  cafesCotainer: css({
    display: "flex",
    flexWrap: "wrap",
  }),
  readMore: css({
    marginTop: 24,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  }),
};
