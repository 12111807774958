/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import { HomeSearch } from "../components/HomeSearch";
import BallonLeft from "../assets/images/ballonLeft.png";
import BallonRight from "../assets/images/ballonRight.png";

export const HomeEyecatch: React.FC = () => (
  <div css={styles.wrapper}>
    <div css={styles.container}>
      <div css={styles.ballonLeft} style={{ backgroundImage: `url(${BallonLeft})` }} />
      <div css={styles.ballonRight} style={{ backgroundImage: `url(${BallonRight})` }} />
      <div css={styles.titleContainer}>
        <h1 css={styles.title}>そろそろ充電切れちゃうんじゃない?</h1>
        <HomeSearch />
      </div>
    </div>
  </div>
);

const styles = {
  wrapper: css({
    backgroundColor: "var(--defaultBrandColor)",
    position: "relative",
    width: "100%",
    "@media (max-width: 991px)": {
      height: 200,
    },
    "@media (min-width: 992px)": {
      height: 300,
    },
  }),
  container: css({
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    "@media (min-width: 992px)": {
      maxWidth: 1120,
      paddingLeft: 20,
      paddingRight: 20,
    },
  }),
  ballonLeft: css({
    backgroundPosition: "center",
    backgroundRepeat: "no-contain",
    backgroundSize: "contain",
    position: "absolute",
    top: 8,
    width: 123,
    height: 114,
    "@media (max-width: 991px)": {
      left: 12,
    },
    "@media (min-width: 992px)": {
      left: 72,
    },
  }),
  ballonRight: css({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    position: "absolute",
    top: 0,
    width: 100,
    height: 138,
    "@media (max-width: 991px)": {
      right: 20,
    },
    "@media (min-width: 992px)": {
      right: 90,
    },
  }),
  titleContainer: css({
    position: "relative",
    padding: "0 15px",
    width: "100%",
  }),
  title: css({
    color: "#333",
    fontWeight: 700,
    margin: 0,
    textAlign: "center",
    "@media (max-width: 991px)": {
      fontSize: 20,
    },
    "@media (min-width: 992px)": {
      fontSize: 32,
    },
  }),
};
