import "ress/ress.css";
import "./variables.css";
import { injectGlobal } from "@emotion/css";

injectGlobal`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ::selection {
    background-color: rgba(66, 133, 245, 0.5);
  }

  html {
    font-size: 62.5%;
  }

  body {
    background-color: var(--defaultBgColor);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "游ゴシック体", "Yu Gothic", YuGothic, Hiragino Sans,
      "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "メイリオ",
      "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 1.4rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    word-break: break-all;
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  a {
    color: var(--linkColor);
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  figure {
    margin: 0;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  hr {
    border: 0;
    border-top: 1px solid var(--subTextColor);
    margin-bottom: 20px;
    margin-top: 20px;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    max-width: 100%;
    width: 100%;

    th {
      text-align: left;
      font-weight: normal;
    }

    thead {
      background-color: var(--defaultComponentBgColor);
    }

    tr {
      th,
      td {
        line-height: 1.42;
        padding: 8px;
        vertical-align: top;
      }

      th {
        vertical-align: bottom;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.25;
  }

  h1,
  h2,
  h3 {
    color: var(--textColor);
    font-weight: 600;
  }

  h1 {
    font-size: 2.8rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4,
  h5,
  h6 {
    color: var(--subTextColor);
    font-weight: 600;
  }

  h4 {
    font-size: 1.8rem;
  }

  h5 {
    font-size: 1.7rem;
  }

  h6 {
    font-size: 1.6rem;
  }

  p,
  ul,
  ol {
    color: var(--textColor);
  }

  p {
    margin: 0 0 20px;
  }

  strong {
    font-weight: 700;
  }

  small {
    font-size: 85%;
  }

  em {
    font-style: normal;
  }

  cite {
    font-style: normal;
  }

  mark {
    background-color: var(--defaultComponentBgColor) 8e1;
    padding: 0.2em;
  }

  abbr {
    border-bottom: 1px dotted var(--subTextColor);
    cursor: help;
  }

  blockquote {
    color: var(--subTextColor);
    margin: 0 0 20px;
    padding: 10px 15px;

    p,
    ul,
    ol {
      &:last-child {
        margin-bottom: 0;
      }
    }

    footer,
    small {
      color: var(--subTextColor);
      display: block;
      font-size: 12px;
      line-height: 1.54;
    }
  }

  address {
    line-height: 1.54;
    margin: 0 0 20px;
  }

  kbd {
    background-color: var(--defaultComponentBgColor);
    border-radius: 2px;
    color: var(--subTextColor);
    font-size: 90%;
    padding: 2px 4px;
  }

  code {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  pre {
    background-color: var(--defaultBgColor);
    border-radius: 2px;
    color: var(--subTextColor);
    display: block;
    font-size: 14px;
    line-height: 1.54;
    margin: 0 0 20px;
    overflow: auto;
    padding: 7px 14px;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;

    code {
      background-color: transparent;
      border-radius: 0;
      color: inherit;
      cursor: text;
      font-size: inherit;
      padding: 0;
    }
  }

  cite,
  em,
  var,
  address,
  dfn {
    font-style: 400;
  }

  .overlay-open {
    overflow: hidden;
  }
`;
