/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Helmet } from "react-helmet";
import { useCafesIndexQuery } from "../graphql/generated/schema";
import { Layout } from "../components/Layout";
import { MainPane } from "../components/MainPane";
import { SidePane } from "../components/SidePane";
import { CafeCards } from "../components/CafeCards";
import { PageScheconAds } from "../components/PageScheconAds";
import { ReadMoreTextButton } from "../components/ReadMoreTextButton";
import { Locales } from "../constants/locales";

type Props = RouteComponentProps;

export const Cafes: React.FC<Props> = ({ location }) => {
  const [isFetchMoreLoading, setFetchMoreLoading] = React.useState<boolean>(false);
  const { loading, error, data, fetchMore } = useCafesIndexQuery({
    variables: {
      after: null,
    },
    onCompleted: () => {
      setFetchMoreLoading(false);
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
  }

  const handleFetchMoreClick = async () => {
    if (data?.newCafes?.pageInfo.hasNextPage) {
      setFetchMoreLoading(true);

      await fetchMore({
        variables: {
          after: data?.newCafes?.pageInfo.endCursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prevResult;
          }
          return Object.assign({}, prevResult, {
            newCafes: {
              ...prevResult?.newCafes,
              pageInfo: fetchMoreResult.newCafes.pageInfo,
              edges: [...(prevResult.newCafes.edges || []), ...(fetchMoreResult.newCafes.edges || [])],
            },
          });
        },
      });
    }
  };

  const title = `電源が使えるカフェ | ${Locales.BRAND.en} - ${Locales.BRAND.ja}`;
  const summary = `${data?.newCafes?.totalCount}件中 1 - ${data?.newCafes?.edges?.length}件`;
  const description = `電源が使えるカフェ一覧`;

  return (
    <>
      <Helmet title={title}>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
      </Helmet>
      <Layout location={location} showHeader showFooter>
        <div css={styles.wrapper}>
          <MainPane style={styles.mainPane}>
            <div css={styles.cafes}>
              <div css={styles.header}>
                <h2 css={styles.title}>{Locales.CAFES.INDEX}</h2>
                <div css={styles.pageInfo}>{summary}</div>
              </div>
              <CafeCards cafes={data?.newCafes?.edges.map((edge) => edge.node)} />
              {!isFetchMoreLoading && data?.newCafes?.pageInfo.hasNextPage && (
                <div css={styles.readMore}>
                  <ReadMoreTextButton loading={loading} onClick={() => handleFetchMoreClick()} />
                </div>
              )}
            </div>
          </MainPane>
          <SidePane style={styles.sidePane}>
            <PageScheconAds />
          </SidePane>
        </div>
      </Layout>
    </>
  );
};

const styles = {
  wrapper: css({
    margin: "0 auto",
    "@media screen and (min-width: 767px)": {
      maxWidth: 768,
    },
    "@media screen and (min-width: 992px)": {
      maxWidth: 1120,
      alignItems: "stretch",
      display: "flex",
    },
    "@media screen and (min-width: 1280px)": {
      width: 1120,
    },
  }),
  mainPane: css({
    paddingTop: 30,
    paddingBottom: 30,
    "@media screen and (min-width: 992px)": {
      paddingLeft: 15,
      paddingRight: 15,
    },
  }),
  sidePane: css({
    paddingTop: 30,
    paddingBottom: 30,
  }),
  cafes: css({
    position: "relative",
    "@media screen and (max-width: 992px)": {
      padding: "0 15px",
    },
  }),
  header: css({
    display: "flex",
    flexDirection: "column",
  }),
  title: css({
    fontSize: 18,
  }),
  pageInfo: css({
    color: "var(--subTextColor)",
    fontSize: 13,
    marginTop: 8,
  }),
  readMore: css({
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
  }),
};
