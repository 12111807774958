const snakeCaseToCamelCase = (str: string) => {
  return str.replace(/([_][a-z])/g, ($1: string) => $1.toUpperCase().replace("_", ""));
};

const camelCaseToSnakeCase = (str: string) => {
  return str.replace(/([A-Z])/g, ($1: string) => `_${$1.toLowerCase()}`);
};

export const parseUrlParameter = (parameterDict: { [key: string]: string | boolean | null | undefined }): string => {
  const params = Object.entries(parameterDict)
    .filter((parameter: [string, string | boolean | null | undefined]) => !!parameter[1])
    .map((parameter: [string, string | boolean | null | undefined]) => {
      if (parameter[1]) {
        return `${camelCaseToSnakeCase(parameter[0])}=${encodeURIComponent(parameter[1])}`;
      }
      return null;
    })
    .join("&");

  return params ? `?${params}` : "";
};

// URLクエリ文字列をオブジェクトに変換します
export const getSearchParamsFromUrl = (url: URL): { [p: string]: string } => {
  const params = new URLSearchParams(url.search.slice(1));

  return [...params.entries()].reduce((dict, e) => {
    return {
      ...dict,
      [snakeCaseToCamelCase(e[0])]: decodeURIComponent(e[1]),
    };
  }, {});
};
