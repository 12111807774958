/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "../components/Layout";
import { MdChevronRight } from "react-icons/md";
import NotFoundImage from "../assets/images/notfound.png";

export const NotFound: FC = () => {
  return (
    <>
      <Helmet meta={[{ name: `robots`, content: `noindex` }]} />
      <Layout style={styles.wrapper} showHeader showFooter>
        <main css={styles.main}>
          <div css={styles.headLine}>
            <div css={styles.statusCode}>
              4<span>0</span>4
            </div>
            <div css={styles.statusText}>
              Page<span>Not</span>Found
            </div>
          </div>
          <div css={styles.content}>
            <div css={styles.contentContext}>
              <p>アクセスしようとしたページが見つかりませんでした。</p>
              <p>
                リンクが無効になっているか、
                <br />
                すでにページが存在しない可能性があります。
              </p>
              <div css={styles.linkContainer}>
                <Link to="/" css={styles.linkText}>
                  <MdChevronRight size={24} />
                  トップページに戻る
                </Link>
              </div>
            </div>
            <div css={styles.contentImage}>
              <img src={NotFoundImage} alt="notfound" width="414" height="288" />
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

const styles = {
  wrapper: css({
    backgroundColor: "var(--defaultComponentBgColor)",
  }),
  main: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 40,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 768,
  }),
  headLine: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    margin: "20px auto",
  }),
  statusCode: css({
    fontSize: 120,
    color: "var(--textColor)",
    lineHeight: 1,
    span: css({
      color: "var(--defaultBrandColor)",
    }),
  }),
  statusText: css({
    display: "flex",
    fontSize: 32,
    color: "var(--textColor)",
    span: css({
      color: "var(--defaultBrandColor)",
      margin: "0 8px",
    }),
  }),
  content: css({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingLeft: 20,
    padingRight: 20,
  }),
  contentContext: css({
    maxWidth: 450,
    p: css({
      margin: "0 0 10px",
      fontSize: 16,
      textAlign: "center",
    }),
  }),
  linkContainer: css({
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  }),
  linkText: css({
    display: "flex",
    color: "var(--subTextColor)",
    fontSize: 16,
    textDecoration: "underline",
    svg: css({
      display: "block",
      color: "var(--mutedTextColor)",
    }),
    "&:hover": {
      color: "var(--defaultBrandColor)",
      svg: css({
        color: "var(--defaultBrandColor)",
      }),
    },
  }),
  contentImage: css({
    flex: "0 0 auto",
    margin: "50px auto 0",
  }),
};
