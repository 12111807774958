/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import { Link } from "react-router-dom";
import { useLatestArticlesQuery } from "../graphql/generated/schema";
import { LatestArticleCard } from "./LatestArticleCard";
import { Locales } from "../constants/locales";
import { Routes } from "../constants/routes";

export const LatestArticles: React.FC = () => {
  const { loading, error, data } = useLatestArticlesQuery();

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
  }

  return (
    <div css={styles.wrapper}>
      <h2 css={styles.title}>{Locales.LATEST_ARTICLES}</h2>
      {data?.articles?.edges?.map((edge) => (
        <LatestArticleCard node={edge.node} key={edge.node.contentId} />
      ))}
      <div css={styles.buttonGroup}>
        <Link css={styles.link} to={`/${Routes.ARTICLES}`}>
          {Locales.LOAD_MORE}
        </Link>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    border: "1px solid var(--borderColor)",
    borderRadius: 4,
    padding: 20,
  }),
  title: css({
    fontSize: 18,
    margin: "0 0 10px",
  }),
  buttonGroup: css({
    padding: 0,
  }),
  link: css({
    alignItems: "center",
    border: "1px solid var(--readMoreButtonBorderColor)",
    borderRadius: 3,
    color: "var(--subTextColor)",
    cursor: "pointer",
    display: "flex",
    fontSize: 14,
    fontWeight: 700,
    justifyContent: "center",
    padding: "12px 16px",
    transition: "all 0.2s var(--transition)",
    width: "100%",
    "&:hover": {
      borderColor: "var(--defaultBrandColor)",
      color: "var(--subTextColor)",
    },
  }),
};
