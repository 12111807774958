/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useCallback, useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useHomeSearchLazyQuery } from "../graphql/generated/schema";
import { useDebounceValue } from "../hooks/useDebounceValue";
import { HomeSearchListItem } from "./HomeSearchListItem";

interface ICoordinate {
  latitude: number;
  longitude: number;
}

export const HomeSearch: React.FC = () => {
  const [fetchHomeSearch, { data, error, refetch }] = useHomeSearchLazyQuery();
  const { value: stationValue, setValue: setStationValue } = useDebounceValue({
    onChangeValue: useCallback(
      (value) => {
        refetch && refetch({ query: value });
      },
      [fetchHomeSearch, refetch]
    ),
  });
  const [coordinate, setCoordinate] = useState<ICoordinate>();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        setCoordinate({ latitude: coords.latitude, longitude: coords.longitude });
      });
    }
  }, []);

  useEffect(() => {
    if (stationValue) {
      if (coordinate) {
        fetchHomeSearch({
          variables: {
            query: stationValue,
            region: {
              latitude: coordinate.latitude,
              longitude: coordinate.longitude,
            },
          },
        });
      } else {
        fetchHomeSearch({ variables: { query: stationValue } });
      }
    }
  }, [fetchHomeSearch, refetch, stationValue]);

  const handleStationValueChange = (value: string) => {
    setStationValue(value);
  };

  if (error) {
    console.error(error);
  }

  return (
    <div css={styles.wrapper}>
      <div css={styles.searchGroup}>
        <input
          type="search"
          css={styles.input}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder="駅名"
          name="stationValue"
          defaultValue={stationValue}
          onChange={(event) => handleStationValueChange(event.target.value)}
        />
        <FiSearch css={styles.searchIcon} size={24} />
      </div>
      <div css={styles.searchResultContainer}>
        <div css={styles.searchResult}>
          {stationValue === "" ? null : (
            <>
              {data?.stations.edges.map((edge) => (
                <HomeSearchListItem key={edge.node.id} station={edge.node} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    margin: "16px auto 0",
    position: "relative",
    "@media screen and (min-width: 992px)": {
      maxWidth: 700,
    },
  }),
  searchGroup: css({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  input: css({
    appearance: "none",
    background: "none",
    backgroundColor: "var(--inputBgColor)",
    border: "none",
    borderRadius: 8,
    boxShadow: "none",
    color: "var(--textColor)",
    display: "block",
    fontSize: 16,
    lineHeight: 1,
    outline: "none",
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 48,
    paddingRight: 16,
    transition: "all 0.1s var(--transition)",
    width: "100%",
    "&::placeholder": {
      color: "var(--mutedTextColor)",
      textAlign: "left",
    },
  }),
  searchIcon: css({
    color: "var(--defaultBrandColor)",
    pointerEvents: "none",
    position: "absolute",
    top: 12,
    left: 12,
  }),
  searchResultContainer: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    border: "none",
    borderRadius: 8,
    boxShadow: "var(--boxShadow)",
    display: "block",
    position: "absolute",
    paddingLeft: 15,
    paddingRight: 15,
    left: 0,
    width: "100%",
    top: 56,
    zIndex: 99,
    overflow: "hidden",
    "@media screen and (max-width: 991px)": {
      maxHeight: 250,
    },
    "@media screen and (min-width: 992px)": {
      maxHeight: 400,
    },
  }),
  searchResult: css({
    height: "100%",
    overflowY: "auto",
    "@media screen and (max-width: 991px)": {
      maxHeight: 300,
    },
    "@media screen and (min-width: 992px)": {
      maxHeight: 400,
    },
  }),
};
