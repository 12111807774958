/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import { CafePage_CafeFragment } from "../graphql/generated/schema";
import { FiShare } from "react-icons/fi";
import { uri } from "../constants/uri";
import BadgePng from "../assets/images/badge.png";

type Props = {
  cafe: CafePage_CafeFragment;
  cafeName: string;
  enableShareApi: boolean;
  pathname: string;
};

export const CafeTitle: React.FC<Props> = ({ cafe, cafeName, enableShareApi, pathname }) => {
  const { access, official } = cafe;

  const handleShare = () => {
    (navigator as any)
      .share({
        title: `電源カフェ情報`,
        text: cafeName,
        url: `${uri.dengencafe}${pathname}`,
      })
      .then(() => {
        console.log("Successfully sharing");
      })
      .catch((error: any) => {
        console.log("Error sharing", error);
      });
  };

  return (
    <div css={styles.wrapper}>
      <div css={styles.titleContainer}>
        <div css={styles.titleInner}>
          <div css={[(enableShareApi && styles.innerEnableShare) || styles.innerDisableShare]}>
            {official ? (
              <h1 css={styles.cafeName}>
                <span>
                  {cafeName}
                  <img css={styles.badge} src={BadgePng} height="21" width="21" alt="" />
                </span>
              </h1>
            ) : (
              <h1 css={styles.cafeName}>{cafeName}</h1>
            )}
            <p css={styles.access}>{access}</p>
          </div>
          {enableShareApi && (
            <button type="button" css={styles.shareButton} onClick={() => handleShare()}>
              <FiShare size={24} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    position: "relative",
    "@media (max-width: 991px)": {
      paddingLeft: 15,
      paddingRight: 15,
    },
    "@media (min-width: 992px)": {},
  }),
  access: css({
    color: "var(--subTextColor)",
    margin: 0,
    fontSize: 14,
  }),
  cafeName: css({
    color: "var(--textColor)",
    display: "inline-flex",
    margin: 0,
    "@media (max-width: 991px)": {
      fontSize: 18,
      lineHeight: "24px",
    },
    "@media (min-width: 992px)": {
      fontSize: 24,
      lineHeight: "32px",
    },
  }),
  badge: css({
    display: "inline",
    verticalAlign: "top",
    margin: 0,
    marginTop: 2,
    "@media (max-width: 991px)": {
      height: 20,
      width: 20,
    },
    "@media (min-width: 992px)": {
      height: 28,
      width: 28,
    },
  }),
  titleContainer: css({
    position: "relative",
    marginTop: 20,
  }),
  titleInner: css({
    display: "flex",
    alignItems: "center",
    "@media (max-width: 991px)": {},
    "@media (min-width: 992px)": {},
  }),
  innerDisableShare: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }),
  innerEnableShare: css({
    width: "calc(100% - 48px)",
  }),
  shareButton: css({
    alignItems: "center",
    appearance: "none",
    background: "none",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    textAlign: "center",
    transition: "all 0.15s var(--transition)",
    width: 48,
    height: 48,
    flex: "0 0 auto",
    marginLeft: 10,
  }),
};
