/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";

export const ArticleBody: React.FC<{ id: string; content: string }> = ({ id, content }) => {
  if (!id) return null;

  return <div css={styles.wrapper} dangerouslySetInnerHTML={{ __html: content.trim().replace(/[\r\n|\n|\r]/g, "") }} />;
};

const styles = {
  wrapper: css({
    color: "var(--textColor)",
    fontSize: 16,
    lineHeight: 1.85,
    h1: css({
      borderBottom: "2px solid var(--defaultBrandColor)",
      color: "var(--textColor)",
      fontWeight: 700,
      margin: "0 0 20px",
      paddingBottom: 10,
      fontSize: 28,
    }),
    h2: css({
      borderLeft: "5px solid var(--defaultBrandColor)",
      color: "var(--textColor)",
      fontWeight: 700,
      marginBottom: 20,
      marginTop: 30,
      paddingLeft: 20,
      fontSize: 24,
    }),
    h3: css({
      borderBottom: "2px solid var(--borderDarkColor)",
      color: "var(--textColor)",
      fontWeight: 700,
      marginBottom: 20,
      marginTop: 30,
      paddingBottom: 5,
      fontSize: 20,
    }),
    h4: css({
      color: "var(--subTextColor)",
      fontWeight: 700,
      marginBottom: 20,
      marginTop: 30,
      fontSize: 18,
    }),
    h5: css({
      color: "var(--subTextColor)",
      fontWeight: 700,
      marginBottom: 20,
      marginTop: 30,
      fontSize: 17,
    }),
    h6: css({
      color: "var(--subTextColor)",
      fontWeight: 700,
      marginBottom: 20,
      marginTop: 30,
      fontSize: 16,
    }),
    p: css({
      color: "var(--textColor)",
      marginBottom: 20,
      marginTop: 20,
    }),
    strong: css({
      fontWeight: 700,
    }),
    img: css({
      display: "block",
      margin: "20px auto",
      maxWidth: "100%",
    }),
    a: css({
      color: "var(--primaryColor)",
      textDecoration: "none",
      "&:hover": css({
        color: "var(--primaryColor)",
        textDecoration: "underline",
      }),
    }),
    ul: css({
      margin: "0 0 20px",
      padding: "0 0 0 20px",
    }),
    ol: css({
      margin: "0 0 20px",
      padding: "0 0 0 20px",
    }),
  }),
};
