export const Locales = {
  BRAND: {
    ja: `電源カフェ`,
    en: `DENGENCAFE`,
  },
  EYECATCH: {
    PLACEHOLDER: "駅名・地名",
  },
  CAFES: {
    INDEX: `電源が使えるカフェ`,
  },
  ARTICLES: {
    INDEX: `記事一覧`,
  },
  LATEST_ARTICLES: `新着記事`,
  LATEST_CAFES: `新着電源カフェ`,
  POPULAR_ARTICLES: `人気記事`,
  CAFE_INFOMATION_TITLE: `店舗情報`,
  CAFE_DETAILS_TITLE: `店舗詳細`,
  CAFE_RELATED_CAFES_TITLE: `近くのカフェ`,
  CAFE_MAP_TITLE: `地図`,
  CAFE_LEAK_FORM: `リークフォーム`,
  CAFE_CORRECT_REQUEST: `訂正リクエスト`,
  CAFE: {
    OUTLETS: `充電コンセントの数`,
    POWER_NUM: `充電コンセントの多さ`,
    WIFI: `Wifi`,
    DOCOMO: `docomo`,
    AU: `au`,
    SOFTBANK: `SoftBank`,
    BUSINESS_HOUR: `営業時間`,
    HOLIDAYS: `定休日`,
    MOBILE_BATTERY: `モバイルバッテリー`,
    HEATED_TABACCO: `加熱式タバコ`,
    NOTES: `備考欄`,
    REVIEW: `レビュー`,
    RECOMMENDATION: `おすすめ`,
    DEMERIT: `注意ポイント`,
    URL: `URL`,
    MAP: {
      ACTION: `行き先を調べる`,
    },
    TOAST: {
      TEXT: `店舗写真のご提供、お待ちしています！`,
    },
  },
  SEARCH: {
    INDEX: `検索結果`,
    PLACEHOLDER: "キーワードで検索",
  },
  NAVIGATION: {
    ARTICLES: `マガジン`,
    FORM: `お問い合わせ`,
    ABOUT: `電源カフェとは`,
    MEDIA: `メディア掲載`,
    COMPANY: `会社情報`,
    TERMS: `利用規約`,
    RELEASE: `ニュースリリース`,
    PRIVACY: `プライバシーポリシー`,
  },
  HEADER: {
    TITLE: `DENGENCAFE | 電源カフェ（充電コンセント、Wi-Fi等）現在地、目的地近くのスポット検索サイト`,
    DESCRIPTION: `外出先でPC作業やMTG、ゲーム目的でスマホ・ノートPC・タブレットなどを充電できる電源カフェを中心に紹介。北海道から東京（新宿、渋谷）、大阪、福岡、沖縄まで日本全国の電源コンセント、Wi-Fiがあるカフェ・喫茶店やレストラン、バー、図書館など近くの充電スポットを検索可能。電源カフェは全て実地調査した情報のみ掲載しています。`,
  },
  FOOTER: {
    DESCRIPTION: [
      `電源（コンセント）が使えるカフェを探せる`,
      `スマホ・タブレット・PCユーザーのための充電スポット検索サイト`,
      `ノマドに必要なWiFi、禁煙喫煙、混雑具合、電源口数も掲載`,
      `電源カフェでは実地調査した情報のみを掲載しています`,
    ],
    MENU: `メニュー`,
    CATEGORY: `カテゴリー`,
    ABOUT: `DENGENCAFEとは`,
  },
  LOAD_MORE: `もっとみる`,
  NOMACHI_STATUS: {
    AVAILABLE: `空席あり`,
    FEW_LEFT: `空席わずか`,
    CROWDED: `混雑中`,
    IN_PREPARATION: `準備中`,
    CLOSE: `CLOSE`,
  },
  CAFE_SMOKING: {
    CigaretteAtSeat: "席で喫煙可能（紙巻・電子）",
    CigaretteAtSpace: "喫煙スペース有（紙巻・電子）",
    EcigaretteAtSeat: "席で喫煙可能（電子タバコのみ）",
    EcigaretteAtSpace: "喫煙スペース有（電子タバコのみ）",
    NonSmoking: "完全禁煙",
    Unknown: "不明",
  },
};
