export const uri = {
  dengencafe: `https://dengen-cafe.com`,
  dengencafeCorp: `https://dengencafe.com`,
  privacyPolicy: `https://dengen-cafe.com/archives/31665`,
  appStore: `https://apps.apple.com/jp/app/%E9%9B%BB%E6%BA%90%E3%82%AB%E3%83%95%E3%82%A7/id782308388`,
  googlePlayStore: `https://play.google.com/store/apps/details?id=com.dengencafeapp`,
  chargeSpot: `https://www.chargespot.jp`,
};

export const formUrl = {
  leakForUser: `https://share.hsforms.com/1sNbL87kZQj2tvIeC4V_k8Q45zg3`,
  leakForCafe: `https://share.hsforms.com/1eQBrbg-XRI2i9rSQlY2rbw45zg3`,
  leakForFix: `https://share.hsforms.com/1QWOFBK_mSz6AEVz_gtDn8A45zg3`,
  displayAds: `https://share.hsforms.com/1hT3CTnhfSYuMwlmAnM9fTQ45zg3`,
  contact: `https://share.hsforms.com/1ty0biW4kSd6QiidxeTivjg45zg3`,
  media: `https://share.hsforms.com/1AE3cDiMAQ7ihLPu48GL56Q45zg3`,
  bussiness: `https://share.hsforms.com/1Q3rTVsnET2-eu2bZiOA-Pw45zg3`,
};
