/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ArticleCard } from "../components/ArticleCard";
import { Layout } from "../components/Layout";
import { MainPane } from "../components/MainPane";
import { SidePane } from "../components/SidePane";
import { Locales } from "../constants/locales";
import { microCmsClient, ArticleResult } from "../utils/microCmsClient";
import { ReadMoreTextButton } from "../components/ReadMoreTextButton";

export const Articles: React.FC<{}> = () => {
  const [articles, setArticles] = useState<ArticleResult[]>([]);
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await microCmsClient.getList({
          endpoint: "articles",
          queries: {
            limit: 20,
          },
        });
        setArticles(result.contents);
        setTotalCount(result.totalCount);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const fetchMore = async () => {
    try {
      setLoading(true);
      const result = await microCmsClient.getList({
        endpoint: "articles",
        queries: {
          limit: 20,
          offset: articles.length,
        },
      });

      setArticles([...articles, ...result.contents]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet title={`${Locales.NAVIGATION.ARTICLES} | ${Locales.BRAND.en}(${Locales.BRAND.ja})`}>
        <meta
          property="og:title"
          content={`${Locales.NAVIGATION.ARTICLES} | ${Locales.BRAND.en}(${Locales.BRAND.ja})`}
        />
      </Helmet>
      <Layout showHeader showFooter>
        <div css={styles.container}>
          <MainPane style={styles.mainPane}>
            <div css={styles.articles}>
              <div css={styles.header}>
                <h2 css={styles.title}>{Locales.ARTICLES.INDEX}</h2>
              </div>
              {totalCount && console.log(totalCount)}
              <div css={styles.body}>
                {articles?.map((article) => (
                  <ArticleCard key={article.id} article={article} />
                ))}
              </div>
            </div>
            {totalCount && articles.length < totalCount && (
              <div css={styles.fetchMore}>
                <ReadMoreTextButton loading={loading} onClick={() => fetchMore()} />
              </div>
            )}
          </MainPane>
          <SidePane style={styles.sidePane} />
        </div>
      </Layout>
    </>
  );
};

const styles = {
  container: css({
    margin: "0 auto",
    "@media screen and (min-width: 767px)": {
      maxWidth: 768,
    },
    "@media screen and (min-width: 992px)": {
      maxWidth: 1120,
      alignItems: "stretch",
      display: "flex",
    },
    "@media screen and (min-width: 1280px)": {
      width: 1120,
    },
  }),
  mainPane: css({
    paddingTop: 30,
    paddingBottom: 30,
    "@media screen and (min-width: 992px)": {
      paddingLeft: 15,
      paddingRight: 15,
    },
  }),
  sidePane: css({
    paddingTop: 30,
    paddingBottom: 30,
  }),
  articles: css({
    position: "relative",
    "@media screen and (max-width: 992px)": {
      padding: "0 15px",
    },
  }),
  header: css({
    display: "flex",
    alignItems: "flex-end",
    margin: "0 0 20px",
  }),
  title: css({
    color: "var(--textColor)",
    fontSize: 18,
  }),
  summary: css({
    color: "var(--subTextColor)",
    fontSize: 14,
    margin: "0 0 0 10px",
  }),
  body: css({
    position: "relative",
    "@media screen and (min-width: 992px)": {
      alignItems: "stretch",
      display: "flex",
      flexWrap: "wrap",
      marginLeft: -10,
      marginRight: -10,
    },
  }),
  fetchMore: css({
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
  }),
};
