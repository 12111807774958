/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import isEmpty from "lodash.isempty";
import { CafePage_CafeFragment } from "../graphql/generated/schema";
import { GoogleAds } from "./GoogleAds";
import { Routes } from "../constants/routes";
import BadgePng from "../assets/images/badge.png";
import NoImage from "../assets/images/no_image.jpg";

type Props = {
  cafes?: CafePage_CafeFragment[];
};

export const SearchCafeCards: React.FC<Props> = ({ cafes }) => {
  if (!cafes) return null;

  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        {cafes.map((cafe) => {
          const { id, access, path, brand, branchName, businessHours, official, description, pictures } = cafe;
          const cafeName = `${brand.name} ${branchName ? ` ${branchName}` : ""}${
            (description && `【${description}】`) || ""
          }`;

          return (
            <Fragment key={id}>
              <div css={styles.card}>
                <Link css={styles.link} to={`/${Routes.CAFES}/${path}`}>
                  <div css={styles.imageContainer}>
                    <div
                      css={styles.image}
                      style={{ backgroundImage: `url(${(!isEmpty(pictures) && pictures[0].url) || NoImage})` }}
                    />
                  </div>
                  <div css={styles.cafe}>
                    {official ? (
                      <h3 css={styles.cafeName}>
                        <span>
                          {cafeName}
                          <img css={styles.officialBadge} src={BadgePng} height="20" width="20" alt="" />
                        </span>
                      </h3>
                    ) : (
                      <h3 css={styles.cafeName}>{cafeName}</h3>
                    )}
                    {businessHours && <p css={styles.businessHours}>{businessHours}</p>}
                    <p css={styles.access}>{access}</p>
                  </div>
                </Link>
              </div>
            </Fragment>
          );
        })}
        <div css={styles.ads}>
          <GoogleAds slot="6426025599" format="fluid" layoutKey="-hh+e-t-8b+ip" />
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    position: "relative",
    "@media (max-width: 991px)": {
      paddingLeft: 15,
      paddingRight: 15,
    },
  }),
  container: css({
    position: "relative",
    "@media (max-width: 619px)": {
      display: "block",
    },
    "@media (min-width: 620px)": {
      display: "flex",
      flexWrap: "wrap",
      marginRight: -20,
    },
  }),
  card: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    borderRadius: 6,
    position: "relative",
    overflow: "hidden",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.05)",
    "@media (max-width: 619px)": {
      "& + &": {
        marginTop: 20,
      },
    },
    "@media (min-width: 620px)": {
      marginBottom: 20,
      marginRight: 20,
      width: "calc(50% - 20px)",
    },
    "@media (min-width: 992px)": {
      width: "calc(33.3333% - 20px)",
    },
    "@media (min-width: 1280px)": {
      width: "calc(50% - 20px)",
    },
    "@media (min-width: 1600px)": {
      width: "calc(25% - 20px)",
    },
  }),
  link: css({
    display: "block",
  }),
  imageContainer: css({
    backgroundColor: "var(--lighterTextColor)",
    position: "relative",
    width: "100%",
    "@media (max-width: 619px)": {
      height: 150,
    },
    "@media (min-width: 620px)": {
      height: 185,
    },
  }),
  image: css({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  }),
  cafe: css({
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
  }),
  cafeName: css({
    color: "var(--textColor)",
    fontSize: 16,
    lineHeight: "22px",
    fontWeight: 700,
    margin: 0,
  }),
  officialBadge: css({
    display: "inline",
    verticalAlign: "top",
    marginTop: 1,
    width: 20,
    height: 20,
  }),
  businessHours: css({
    color: "var(--subTextColor)",
    marginTop: 10,
    marginBottom: 0,
    fontSize: 12,
    "@media (max-width: 991px)": {
      display: "none",
    },
    "@media (min-width: 992px)": {
      display: "block",
    },
  }),
  access: css({
    color: "var(--subTextColor)",
    marginTop: 10,
    marginBottom: 0,
    fontSize: 12,
  }),
  ads: css({
    marginRight: 20,
    padding: "0 20px",
    width: "100%",
  }),
};
