/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React from "react";
import { uri } from "../constants/uri";
import DevicesPng from "../assets/images/devices.png";
import PinsPng from "../assets/images/pins.png";
import AppStoreBadge from "../assets/svg/Download_on_the_App_Store_Badge_JP.svg";
import GooglePlayBadge from "../assets/images/google-play-badge.png";
import { ExternalLink } from "../components/ExternalLink";

export const HomeAds: React.FC = () => {
  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        <div css={styles.devices} style={{ backgroundImage: `url(${DevicesPng})` }} />
        <div css={styles.pins} style={{ backgroundImage: `url(${PinsPng})` }} />
        <div css={styles.body}>
          <h2 css={styles.titlePc}>アプリなら現在地、 目的地からサクッと探せます</h2>
          <h2 css={styles.titleSp}>
            アプリなら現在地、
            <br />
            目的地からサクッと探せます
          </h2>
          <p css={styles.description}>
            日本全国約6000店舗のコンセントやWiFやのあるカフェや喫茶店、レストランを掲載。
            <br />
            あなたの現在地や目的地近隣の電源カフェを手早く探しましょう。
          </p>
          <div css={styles.badges}>
            <ExternalLink target="_blank" to={uri.appStore} css={styles.appLink}>
              <img css={styles.appBadge} src={AppStoreBadge} alt="" />
            </ExternalLink>
            <ExternalLink target="_blank" to={uri.googlePlayStore} css={styles.gPlayLink}>
              <img css={styles.gPlayBadge} src={GooglePlayBadge} alt="" />
            </ExternalLink>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapper: css({
    backgroundColor: "#F6F8FA",
    position: "relative",
    "&:before": {
      backgroundColor: "var(--defaultBrandColor)",
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
    },
    "@media (max-width: 991px)": {
      "&:before": {
        height: 8,
      },
    },
    "@media (min-width: 992px)": {
      "&:before": {
        height: 60,
      },
    },
  }),
  container: css({
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    display: "flex",
    "@media (max-width: 991px)": {
      padding: 15,
    },
    "@media (min-width: 992px)": {
      maxWidth: 1120,
      paddingLeft: 20,
      paddingRight: 20,
      height: 380,
    },
  }),
  devices: css({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    position: "absolute",
    bottom: 0,
    left: 50,
    width: 334,
    height: 322,
    "@media (max-width: 991px)": {
      display: "none",
    },
  }),
  pins: css({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    position: "absolute",
    top: 32,
    "@media (max-width: 991px)": {
      right: 20,
    },
    "@media (min-width: 992px)": {
      right: 50,
      width: 70,
      height: 68,
    },
  }),
  body: css({
    position: "relative",
    "@media (min-width: 992px)": {
      width: 600,
      marginLeft: "auto",
      marginTop: 105,
    },
  }),
  titlePc: css({
    color: "#333",
    fontWeight: 700,
    margin: 0,
    fontSize: 26,
    lineHeight: 1.65,
    letterSpacing: "-0.015em",
    "@media (max-width: 991px)": {
      display: "none",
    },
  }),
  titleSp: css({
    color: "#333",
    fontWeight: 700,
    margin: "16px 0 0",
    lineHeight: 1.65,
    letterSpacing: "-0.015em",
    fontSize: 20,
    "@media (min-width: 992px)": {
      display: "none",
    },
  }),
  description: css({
    color: "#666",
    margin: "16px 0 0",
    fontSize: 14,
    "@media (max-width: 991px)": {},
    "@media (min-width: 992px)": {},
  }),
  badges: css({
    display: "flex",
    alignItems: "center",
    marginTop: 16,
  }),
  appLink: css({}),
  appBadge: css({
    display: "block",
    width: 122,
  }),
  gPlayLink: css({}),
  gPlayBadge: css({
    display: "block",
    width: 170,
  }),
};
