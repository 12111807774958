/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import React from "react";

const styles = {
  logo: css({
    position: "relative",
  }),
  textLogo: css({
    position: "relative",
  }),
  textLogoSvg: css({
    display: "block",
    fill: "var(--textColor)",
    width: "auto",
  }),
  iconLogo: css({
    position: "relative",
  }),
  iconLogoSvg: css({
    display: "block",
    fill: "var(--textColor)",
    width: "auto",
  }),
};

type LogoProps = {
  children?: React.ReactNode;
  className?: string;
  style?: SerializedStyles;
  height?: number;
};

type SvgProps = {
  className?: string;
  height: number;
};

const SvgIconLogo: React.FC<SvgProps> = ({ className, height }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 154 148" style={{ height }}>
    <g>
      <path d="M151.44,13 C147,5 137.72,0 127.19,0 L26.72,0 C16.2,0 6.91,5 2.47,13 C-1.02387653,19.1530236 -0.792406967,26.7413674 3.07,32.67 C11.86,46.67 18.21,64.17 24.36,81.13 C39.36,122.57 50.36,147.73 76.96,147.73 C103.56,147.73 114.55,122.57 129.57,81.11 C135.71,64.17 142.06,46.65 150.85,32.67 C154.716054,26.741603 154.94378,19.1494546 151.44,13 Z M144.12,28.44 C134.88,43.15 128.38,61.07 122.12,78.44 C108.26,116.6 98.32,139.79 77,139.79 C55.68,139.79 45.66,116.6 31.82,78.41 C25.54,61.07 19.04,43.14 9.82,28.41 C7.50092384,24.9282666 7.35752269,20.4324468 9.45,16.81 C12.45,11.41 19.23,7.92 26.74,7.92 L127.19,7.92 C134.71,7.92 141.5,11.41 144.49,16.81 C146.594974,20.4399247 146.45145,24.9512169 144.12,28.44 Z" />
    </g>
  </svg>
);

const SvgTextLogo: React.FC<SvgProps> = ({ className, height }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 393 46" style={{ height }}>
    <g>
      <path d="M9.42,1.83 L0,1.83 L0,44.94 L9.42,44.94 C23.04,44.94 32.19,36.27 32.19,23.35 C32.19,10.28 23.26,1.83 9.42,1.83 Z M9.6,41.83 L3.6,41.83 C3.50014894,41.8326997 3.40344835,41.7949127 3.3318818,41.7252295 C3.26031525,41.6555463 3.21999998,41.5598875 3.21999998,41.46 L3.21999998,5.29 C3.21999998,5.0801318 3.3901318,4.91 3.6,4.91 L9.71999998,4.91 C21.28,4.91 28.72,12.18 28.72,23.41 C28.72,34.64 21.23,41.85 9.6,41.85 L9.6,41.83 Z" />
      <path d="M41.46,44.94 L63.55,44.94 L63.55,41.85 L45.1,41.85 C45.0001489,41.8526997 44.9034484,41.8149127 44.8318818,41.7452295 C44.7603153,41.6755463 44.7199635,41.5798875 44.72,41.48 L44.72,24.77 C44.7199635,24.6701125 44.7603153,24.5744537 44.8318818,24.5047705 C44.9034484,24.4350873 45.0001489,24.3973003 45.1,24.4 L62.26,24.4 L62.26,21.4 L45.1,21.4 C44.8901318,21.4 44.72,21.2298682 44.72,21.02 L44.72,5.29 C44.72,5.18921773 44.7600356,5.09256325 44.8312994,5.02129942 C44.9025632,4.9500356 44.9992177,4.91 45.1,4.91 L63.55,4.91 L63.55,1.83 L41.46,1.83 L41.46,44.94 Z" />
      <path d="M102,44.94 L105.09,44.94 L105.09,1.83 L101.82,1.83 L101.82,38.11 C101.8227,38.2098511 101.784913,38.3065516 101.71523,38.3781182 C101.645546,38.4496847 101.549888,38.4900365 101.45,38.49 C101.309928,38.4897403 101.176128,38.4318809 101.08,38.33 L75.78,1.83 L72.1,1.83 L72.1,44.94 L75.36,44.94 L75.36,8 C75.3654217,7.79404715 75.5339758,7.62992865 75.74,7.63 C75.8800723,7.6302597 76.013872,7.68811906 76.11,7.79 L102,44.94 Z" />
      <path d="M148.41,42.44 L148.41,22 L145.21,22 L145.21,40.1 C145.208196,40.2462235 145.122645,40.3784386 144.99,40.44 C142.009602,41.9137298 138.713503,42.6347515 135.39,42.54 C125.25,42.54 117.89,34.54 117.89,23.39 C117.89,12.54 125.44,4.39 135.44,4.39 C139.660043,4.39032328 143.803201,5.51933393 147.44,7.66 L147.44,3.92 C143.75464,1.99557659 139.657553,0.993608961 135.5,0.999969617 C123.3,0.999969617 114.5,10.43 114.5,23.41 C114.5,36.16 123.5,45.77 135.38,45.77 C139.939539,45.8163528 144.43178,44.6682974 148.41,42.44 Z" />
      <path d="M159,44.94 L181.1,44.94 L181.1,41.85 L162.64,41.85 C162.540149,41.8526997 162.443448,41.8149127 162.371882,41.7452295 C162.300315,41.6755463 162.259964,41.5798875 162.26,41.48 L162.26,24.77 C162.259964,24.6701125 162.300315,24.5744537 162.371882,24.5047705 C162.443448,24.4350873 162.540149,24.3973003 162.64,24.4 L179.8,24.4 L179.8,21.4 L162.64,21.4 C162.430132,21.4 162.26,21.2298682 162.26,21.02 L162.26,5.29 C162.26,5.0801318 162.430132,4.91 162.64,4.91 L181.1,4.91 L181.1,1.83 L159,1.83 L159,44.94 Z" />
      <path d="M219.51,44.94 L222.6,44.94 L222.6,1.83 L219.33,1.83 L219.33,38.11 C219.3327,38.2098511 219.294913,38.3065516 219.22523,38.3781182 C219.155546,38.4496847 219.059888,38.4900365 218.96,38.49 C218.819928,38.4897403 218.686128,38.4318809 218.59,38.33 L193.32,1.83 L189.64,1.83 L189.64,44.94 L192.9,44.94 L192.9,8 C192.905422,7.79404715 193.073976,7.62992865 193.28,7.63 C193.420072,7.6302597 193.553872,7.68811906 193.65,7.79 L219.51,44.94 Z" />
      <path d="M271,45.77 C274.863196,45.8376015 278.675512,44.8819349 282.05,43 L282.05,39.31 C278.73595,41.4628266 274.861771,42.5930855 270.91,42.56 C262.26,42.56 253.3,35.38 253.3,23.35 C253.126908,18.0613029 255.141897,12.9351999 258.87,9.18 C262.06847,6.01756452 266.372307,4.22429924 270.87,4.18 C274.813298,4.15682099 278.680157,5.26758849 282.01,7.38 L282.01,3.79 C278.653513,1.89719475 274.852839,0.934081069 271,1 C260.77,1 249.889909,8.81 249.889909,23.29 C249.86,34.19 257.26,45.77 271,45.77 Z" />
      <path d="M343.87,4.91 L362.56,4.91 L362.56,1.83 L340.23,1.83 L340.23,44.94 L343.49,44.94 L343.49,25.83 C343.495422,25.6240472 343.663976,25.4599286 343.87,25.46 L361.73,25.46 L361.73,22.37 L343.87,22.37 C343.663976,22.3700714 343.495422,22.2059528 343.49,22 L343.49,5.29 C343.49,5.0801318 343.660132,4.91 343.87,4.91 L343.87,4.91 Z" />
      <path d="M370.54,44.94 L392.64,44.94 L392.64,41.85 L374.18,41.85 C373.975655,41.85 373.81,41.6843454 373.81,41.48 L373.81,24.77 C373.81,24.5656546 373.975655,24.4 374.18,24.4 L391.34,24.4 L391.34,21.4 L374.18,21.4 C373.974047,21.3945783 373.809929,21.2260242 373.81,21.02 L373.81,5.29 C373.809929,5.08397581 373.974047,4.91542168 374.18,4.91 L392.64,4.91 L392.64,1.83 L370.54,1.83 L370.54,44.94 Z" />
      <path d="M333.51,5.11 C331.971443,2.58344875 329.186002,1.08742215 326.23,1.2 L296.05,1.2 C293.093998,1.08742215 290.308557,2.58344875 288.77,5.11 C287.739916,6.96203758 287.828631,9.23392748 289,11 C291.64,15.2 293.55,20.46 295.39,25.55 C299.9,38 303.21,45.55 311.19,45.55 C319.17,45.55 322.48,38 326.99,25.55 C328.83,20.46 330.74,15.2 333.38,11 C334.519991,9.21503761 334.570127,6.94352187 333.51,5.11 Z M331.31,9.74 C328.53,14.16 326.58,19.54 324.69,24.74 C320.54,36.21 317.55,43.17 311.14,43.17 C304.73,43.17 301.74,36.17 297.58,24.74 C295.7,19.53 293.75,14.15 290.97,9.74 C290.272242,8.69603955 290.229567,7.3459435 290.86,6.26 C291.996416,4.51516328 293.969663,3.50002479 296.05,3.59 L326.23,3.59 C328.310337,3.50002479 330.283584,4.51516328 331.42,6.26 C332.050433,7.3459435 332.007758,8.69603955 331.31,9.74 L331.31,9.74 Z" />
    </g>
  </svg>
);

export const Logo: React.FC<LogoProps> = ({ children, className }) => {
  if (!children) return null;

  return <div css={[styles.logo, className]}>{children}</div>;
};

export const TextLogo: React.FC<LogoProps> = ({ style, height = 16 }) => (
  <div css={[styles.textLogo, style]}>
    <SvgTextLogo css={styles.textLogoSvg} height={height} />
  </div>
);

export const IconLogo: React.FC<LogoProps> = ({ className, height = 16 }) => (
  <div css={[styles.iconLogo, className]}>
    <SvgIconLogo css={styles.iconLogoSvg} height={height} />
  </div>
);
