/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { MdExpandMore, MdCheck } from "react-icons/md";
import { Smoking } from "../graphql/generated/schema";
import { Locales } from "../constants/locales";

type Props = {
  selectedSmoking?: string[];
  onChange: (smoking: Smoking[]) => void;
};

export const renderSmokingText = (status: string) => {
  switch (status) {
    case Smoking.CigaretteAtSeat:
      return Locales.CAFE_SMOKING.CigaretteAtSeat;
    case Smoking.CigaretteAtSpace:
      return Locales.CAFE_SMOKING.CigaretteAtSpace;
    case Smoking.EcigaretteAtSeat:
      return Locales.CAFE_SMOKING.EcigaretteAtSeat;
    case Smoking.EcigaretteAtSpace:
      return Locales.CAFE_SMOKING.EcigaretteAtSpace;
    case Smoking.NonSmoking:
      return Locales.CAFE_SMOKING.NonSmoking;
    case Smoking.Unknown:
      return Locales.CAFE_SMOKING.Unknown;
    default:
      return null;
  }
};

export const SearchCafeSmokingPicker: React.FC<Props> = ({ selectedSmoking, onChange }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const selectedSmokingSet = new Set(selectedSmoking as Smoking[]);
  const isSmokingSelected = selectedSmokingSet.size > 0;

  const handleSmokingChange = (value: Smoking) => {
    if (selectedSmokingSet.has(value)) {
      selectedSmokingSet.delete(value);
    } else {
      selectedSmokingSet.add(value);
    }
    onChange([...selectedSmokingSet]);
  };

  return (
    <div css={styles.wrapper}>
      <OutsideClickHandler onOutsideClick={() => setDropdownOpen(false)}>
        <button
          type="button"
          css={[styles.button, isSmokingSelected && styles.buttonActive]}
          onClick={() => setDropdownOpen(!isDropdownOpen)}
        >
          禁煙・喫煙
          <MdExpandMore size={20} />
        </button>
        {isDropdownOpen && (
          <div css={styles.dropdown}>
            <button
              css={[styles.dropdownButton, !isSmokingSelected && styles.dropdownButtonActive]}
              onClick={() => onChange([])}
            >
              <span css={[styles.dropdownText, !isSmokingSelected && styles.dropdownTextActive]}>指定なし</span>
              {!isSmokingSelected && <MdCheck size={20} />}
            </button>
            {Object.values(Smoking).map((value) => {
              const isActive = selectedSmokingSet.has(value);

              return (
                <button
                  key={value}
                  css={[styles.dropdownButton, isActive && styles.dropdownButtonActive]}
                  type="button"
                  onClick={() => handleSmokingChange(value)}
                >
                  <span css={[styles.dropdownText, isActive && styles.dropdownTextActive]}>
                    {renderSmokingText(value)}
                  </span>
                  {isActive && <MdCheck size={20} />}
                </button>
              );
            })}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

const styles = {
  wrapper: css({
    position: "relative",
  }),
  button: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    borderRadius: 50,
    border: "1px solid var(--defaultComponentBgColor)",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 15,
    fontSize: 14,
    lineHeight: "20px",
    color: "var(--defaultTextColor)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
    display: "flex",
    alignItems: "center",
    "&:hover": css({
      border: "1px solid var(--defaultBrandColor)",
      fontWeight: "medium",
    }),
    svg: css({
      marginLeft: 5,
    }),
  }),
  buttonActive: css({
    backgroundColor: "var(--defaultBrandColor)",
    border: "1px solid var(--defaultBrandColor)",
    fontWeight: "bold",
    boxShadow: "none",
    "&:hover": css({
      backgroundColor: "var(--defaultBrandColor)",
      border: "1px solid var(--defaultBrandColor)",
    }),
  }),
  dropdown: css({
    backgroundColor: "var(--whiteColor)",
    borderRadius: 8,
    marginTop: 8,
    marginBottom: 24,
    width: 300,
    paddingTop: 8,
    paddingBottom: 8,
    boxShadow: "var(--boxShadow)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "absolute",
    zIndex: 100,
    top: "100%",
    right: 0,
  }),
  dropdownButton: css({
    backgroundColor: "var(--whiteColor)",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 14,
    lineHeight: "20px",
    width: "100%",
    textAlign: "left",
    display: "flex",
    "&:hover": css({
      backgroundColor: "var(--grayLighter)",
    }),
  }),
  dropdownButtonActive: css({
    svg: css({
      color: "var(--defaultBrandColor)",
      display: "block",
      marginLeft: "auto",
    }),
  }),
  dropdownText: css({
    width: "calc(100% - 20px)",
  }),
  dropdownTextActive: css({
    fontWeight: "bold",
  }),
};
