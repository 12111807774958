/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { FiBookOpen, FiMail } from "react-icons/fi";
import { Transition } from "react-transition-group";
import { TextLogo } from "./Logo";
import { Routes } from "../constants/routes";
import { Locales } from "../constants/locales";

type Props = {
  className?: string;
  isOpen: boolean;
  onChangeOpenState: () => void;
};

const styles = {
  backDrop: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    bottom: 0,
    display: "none",
    height: "100vh",
    left: 0,
    opacity: 0,
    overflow: "auto",
    position: "fixed",
    right: 0,
    top: 0,
    userSelect: "none",
    zIndex: 999,
  }),
  backDropActive: css({
    display: "block",
  }),
  drawer: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    bottom: 0,
    left: 0,
    top: 0,
    margin: 0,
    maxWidth: 280,
    position: "absolute",
    transition: "transform 0.125s var(--transition)",
    width: "75%",
    zIndex: 999,
  }),
  exited: css({
    opacity: 0,
    boxShadow: "none",
    transform: "translate3d(-100%, 0, 0)",
  }),
  exiting: css({
    boxShadow: "none",
    transform: "translate3d(-100%, 0, 0)",
  }),
  entered: css({
    opacity: 1,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.2)",
    transform: "translate3d(0, 0, 0)",
  }),
  entering: css({
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.2)",
    transform: "translate3d(0, 0, 0)",
  }),
  header: css({
    display: "flex",
  }),
  headerLink: css({
    color: "var(--textColor)",
    display: "block",
  }),
  textLogo: css({
    padding: "24px 16px",
  }),
  body: css({
    position: "relative",
  }),
  link: css({
    color: "var(--textColor)",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    padding: "12px 16px",
    "&:hover": css({
      color: "var(--defaultBrandColor)",
    }),
    svg: css({
      marginRight: 8,
      display: "block",
    }),
  }),
};

export const Drawer: FC<Props> = ({ isOpen, onChangeOpenState }) => (
  <>
    <div css={[styles.backDrop, isOpen && styles.backDropActive]} onClick={onChangeOpenState} />
    <Transition in={isOpen} timeout={150}>
      {(state) => (
        <div
          css={[
            styles.drawer,
            state === "entering" && styles.entering,
            state === "entered" && styles.entered,
            state === "exiting" && styles.exiting,
            state === "exited" && styles.exited,
          ]}
        >
          <div css={styles.header}>
            <Link css={styles.headerLink} to="/">
              <TextLogo style={styles.textLogo} />
            </Link>
          </div>
          <div css={styles.body}>
            <Link css={styles.link} to={`/${Routes.ARTICLES}`}>
              <FiBookOpen size={20} />
              <span>{Locales.NAVIGATION.ARTICLES}</span>
            </Link>
            <Link css={styles.link} to={`/${Routes.FORM}`}>
              <FiMail size={20} />
              <span>{Locales.NAVIGATION.FORM}</span>
            </Link>
          </div>
        </div>
      )}
    </Transition>
  </>
);
