/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React, { useCallback, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Helmet } from "react-helmet";
import { TileLayer, Marker, Popup, Map } from "react-leaflet";
import { isDesktop } from "react-device-detect";
import { useSearchIndexLazyQuery, WiFi, Smoking } from "../graphql/generated/schema";
import { Layout } from "../components/Layout";
import { SearchCafeCards } from "../components/SearchCafeCards";
import { PageScheconAds } from "../components/PageScheconAds";
import { MapPop } from "../components/MapPop";
import { Locales } from "../constants/locales";
import { getSearchParamsFromUrl, parseUrlParameter } from "../utils/url";
import { SearchCafeUsagePicker } from "../components/SearchCafeUsagePicker";
import { SearchCafeSmokingPicker } from "../components/SearchCafeSmokingPicker";

type Props = RouteComponentProps;

export const Search: React.FC<Props> = ({ location }) => {
  const [archived] = useState<boolean>(false);
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isCafe, setIsCafe] = useState<boolean>(false);
  const [official, setOfficial] = useState<boolean>(false);
  const [wifi, setWifi] = useState<boolean>(false);
  const [usageIds, setUsageIds] = useState<string[]>([]);
  const [smoking, setSmoking] = useState<Smoking[]>([]);

  const [getCafes, { loading, error, data, refetch }] = useSearchIndexLazyQuery({
    variables: {
      latitude: parseFloat(`${latitude}`),
      longitude: parseFloat(`${longitude}`),
      where: {
        archived,
        isCafe: isCafe || undefined,
        official: official || undefined,
        wifi: (wifi && WiFi.Available) || undefined,
        usageIds: usageIds.length > 0 ? usageIds : undefined,
        smoking: smoking.length > 0 ? smoking : undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  // URLから検索条件を再現します
  useEffect(() => {
    const params: { [key: string]: string } = getSearchParamsFromUrl(new URL(window.location.href));

    setLatitude(params.lat);
    setLongitude(params.lng);
    setName(params.name);
    setIsCafe(params.isCafe === "true");
    setOfficial(params.official === "true");
    setWifi(params.wifi === WiFi.Available);
    setUsageIds(params.usages?.split(",") || []);
    setSmoking((params.smoking?.split(",") as Smoking[]) || []);

    getCafes();
  }, []);

  useEffect(() => {
    if (!refetch) return;

    refetch();
  }, [latitude, longitude, name, official, isCafe, wifi, usageIds, smoking]);

  useEffect(() => {
    const newParameters = parseUrlParameter({
      lat: latitude,
      lng: longitude,
      name,
      isCafe,
      official,
      wifi: wifi && WiFi.Available,
      usages: usageIds?.toString(),
      smokings: smoking?.toString(),
    });

    // 更新したURLに書き換える
    const url = new URL(window.location.href);
    const newUrl = `${url.origin + url.pathname}${newParameters}`;

    window.history.pushState(null, "", newUrl);
  }, [latitude, longitude, name, official, isCafe, wifi, usageIds, smoking]);

  const title = `${name}の${Locales.SEARCH.INDEX} | ${Locales.BRAND.en}(${Locales.BRAND.ja})`;
  const description = `${name}の${Locales.SEARCH.INDEX}を表示しています`;

  const handleIsCafeClick = useCallback(() => {
    setIsCafe(!isCafe);
  }, [isCafe]);

  const handleOfficialClick = useCallback(() => {
    setOfficial(!official);
  }, [official]);

  const handleWifiClick = useCallback(() => {
    setWifi(!wifi);
  }, [wifi]);

  const handleUsageClick = useCallback(
    (value: string[]) => {
      setUsageIds(value);
    },
    [usageIds]
  );

  const handleSmokingClick = useCallback(
    (value: Smoking[]) => {
      setSmoking(value);
    },
    [smoking]
  );

  if (!name) {
    return null;
  }

  if (error) {
    console.error(error);
  }

  return (
    <>
      <Helmet title={title}>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
      </Helmet>
      <Layout style={styles.wrapper} location={location} showHeader showFooter={false}>
        <div css={styles.container}>
          <div css={styles.mainPane}>
            <div css={styles.cafes}>
              <div css={styles.cafesHeader}>
                <h2 css={styles.title}>{`${name}の${Locales.SEARCH.INDEX}`}</h2>
                {isDesktop && (
                  <div css={styles.searchContainer}>
                    <button
                      type="button"
                      css={[styles.buttonFirst, isCafe && styles.buttonActive]}
                      onClick={handleIsCafeClick}
                    >
                      カフェのみ表示
                    </button>
                    <button
                      type="button"
                      css={[styles.button, official && styles.buttonActive]}
                      onClick={handleOfficialClick}
                    >
                      認定カフェ
                    </button>
                    <button type="button" css={[styles.button, wifi && styles.buttonActive]} onClick={handleWifiClick}>
                      Wifiあり
                    </button>
                    <div css={styles.usagesWrapper}>
                      <SearchCafeUsagePicker selectedUsageIds={usageIds} onChange={handleUsageClick} />
                    </div>
                    <div css={styles.usagesWrapper}>
                      <SearchCafeSmokingPicker selectedSmoking={smoking} onChange={handleSmokingClick} />
                    </div>
                  </div>
                )}
              </div>
              {!loading && <SearchCafeCards cafes={data?.newCafes?.edges.map((edge) => edge.node)} />}
            </div>
          </div>
          <div css={styles.sidePane}>
            <div css={styles.map}>
              <div css={styles.mapContainer}>
                <Map center={[parseFloat(`${latitude}`), parseFloat(`${longitude}`)]} zoom={16} scrollWheelZoom={false}>
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {data?.newCafes?.edges.length === 0
                    ? null
                    : data?.newCafes?.edges.map((edge) => {
                        const { node } = edge;
                        const { id, latitude, longitude } = node;
                        if (!latitude || !longitude) return null;

                        return (
                          <Marker key={id} position={{ lat: latitude, lng: longitude }}>
                            <Popup position={{ lat: latitude, lng: longitude }} closeButton={false}>
                              <MapPop key={id} node={node} />
                            </Popup>
                          </Marker>
                        );
                      })}
                </Map>
              </div>
            </div>
          </div>
        </div>
        <PageScheconAds />
      </Layout>
    </>
  );
};

const styles = {
  wrapper: css({
    minHeight: "100vh",
  }),
  container: css({
    "@media (min-width: 992px)": {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
  }),
  mainPane: css({
    position: "relative",
    overflowY: "auto",
    paddingBottom: 20,
    paddingTop: 20,
    "@media (max-width: 991px)": {},
    "@media (min-width: 992px)": {
      paddingLeft: 20,
      paddingRight: 20,
      width: 750,
      height: "calc(100vh - 70px)",
    },
    "@media (min-width: 1280px)": {
      width: `calc(100% - 600px)`,
    },
  }),
  cafes: css({
    position: "relative",
  }),
  cafesHeader: css({
    position: "relative",
    "@media (max-width: 991px)": {
      paddingLeft: 15,
      paddingRight: 15,
    },
  }),
  title: css({
    color: "var(--textColor)",
    fontSize: 18,
    fontWeight: 700,
    marginTop: 0,
    marginBottom: 20,
  }),
  sidePane: css({
    position: "relative",
    overflow: "hidden",
    padding: 20,
    "@media (max-width: 991px)": {
      display: "none",
    },
    "@media (min-width: 992px)": {
      height: "calc(100vh - 70px)",
      width: "calc(100% - 750px)",
    },
    "@media (min-width: 1280px)": {
      width: 600,
    },
  }),
  map: css({
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
  }),
  mapContainer: css({
    height: "100%",
    position: "relative",
    width: "100%",
    ".leaflet-container": css({
      height: "100%",
    }),
  }),
  searchContainer: css({
    marginTop: 20,
    marginBottom: 20,
    display: "flex",
    flexWrap: "wrap",
  }),
  buttonFirst: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    borderRadius: 50,
    border: "1px solid var(--defaultComponentBgColor)",
    color: "var(--defaultTextColor)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
    fontSize: 14,
    lineHeight: "20px",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    "&:hover": css({
      border: "1px solid var(--defaultBrandColor)",
      fontWeight: "medium",
    }),
  }),
  button: css({
    backgroundColor: "var(--defaultComponentBgColor)",
    borderRadius: 50,
    border: "1px solid var(--defaultComponentBgColor)",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 14,
    lineHeight: "20px",
    color: "var(--defaultTextColor)",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
    marginLeft: 10,
    "&:hover": css({
      border: "1px solid var(--defaultBrandColor)",
      fontWeight: "medium",
    }),
  }),
  buttonActive: css({
    backgroundColor: "var(--defaultBrandColor)",
    border: "1px solid var(--defaultBrandColor)",
    fontWeight: "bold",
    boxShadow: "none",
    "&:hover": css({
      backgroundColor: "var(--defaultBrandColor)",
      border: "1px solid var(--defaultBrandColor)",
    }),
  }),
  usagesWrapper: css({
    marginLeft: 10,
  }),
};
