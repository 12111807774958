/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/serialize";
import { FC } from "react";
import { ExternalLink } from "./ExternalLink";
import { GoogleAds } from "./GoogleAds";
import { Locales } from "../constants/locales";
import { uri } from "../constants/uri";
import AdsPc from "../assets/images/ads_pc.png";
import AdsSp from "../assets/images/ads_sp.png";

type Props = {
  baseStyle?: boolean;
  children?: React.ReactNode;
  style?: SerializedStyles;
};

export const SidePane: FC<Props> = ({ baseStyle = true, children, style }) => {
  if (!children) return null;

  return (
    <div css={[styles.wrapper(baseStyle), style]}>
      <ExternalLink css={styles.ads} target="_blank" to={uri.appStore}>
        <img css={styles.adsPc} src={AdsPc} alt={Locales.BRAND.ja} />
        <img css={styles.adsSp} src={AdsSp} alt={Locales.BRAND.ja} />
      </ExternalLink>
      <GoogleAds slot="8855529229" />
      {children}
    </div>
  );
};

const styles = {
  wrapper: (baseStyle) =>
    css(
      {
        position: "relative",
      },
      baseStyle && {
        "@media (min-width: 992px)": {
          flex: "1 0 300px",
          width: 300,
        },
      }
    ),
  ads: css({
    display: "block",
    marginBottom: 20,
    position: "relative",
  }),
  adsPc: css({
    display: "none",
    "@media (min-width: 992px)": {
      display: "block",
    },
  }),
  adsSp: css({
    display: "block",
    "@media (min-width: 992px)": {
      display: "none",
    },
  }),
};
