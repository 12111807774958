/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Helmet } from "react-helmet";
import { LatestArticles } from "../components/LatestArticles";
import { ArticleBody } from "../components/ArticleBody";
import { Script } from "../components/Script";
import { ArticleHeaderTitle } from "../components/ArticleHeaderTitle";
import { ArticleHeaderDescription } from "../components/AritcleHeaderDescription";
import { Layout } from "../components/Layout";
import { MainPane } from "../components/MainPane";
import { SidePane } from "../components/SidePane";
import { Locales } from "../constants/locales";
import { NotFound } from "./NotFound";
import { microCmsClient, ArticleResult } from "../utils/microCmsClient";

type Props = RouteComponentProps<{ articleId: string }>;

const instagramJS = "https://platform.instagram.com/en_US/embeds.js";

export const Article: React.FC<Props> = ({ match, location }) => {
  const [article, setArticle] = useState<ArticleResult | undefined | null>(undefined);

  useEffect(() => {
    (async () => {
      try {
        const result = await microCmsClient.get({
          endpoint: "articles",
          contentId: match.params.articleId,
        });
        setArticle(result);
      } catch (error) {
        console.error(error);
        setArticle(null);
      }
    })();
  }, []);

  if (article === undefined) {
    return null;
  }

  if (article === null) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet title={`${article.title} | ${Locales.BRAND.en}(${Locales.BRAND.ja})`}>
        <meta property="og:title" content={`${article.title} | ${Locales.BRAND.en}(${Locales.BRAND.ja})`} />
        {article.description && <meta property="og:description" content={article.description} />}
        {article.description && <meta name="description" content={article.description} />}
      </Helmet>
      <Layout location={location} showHeader showFooter>
        <div css={styles.header}>
          <ArticleHeaderTitle title={article.title} image={article.cover.url} />
          {article.description && (
            <ArticleHeaderDescription description={article.description} createdAt={article.createdAt} />
          )}
        </div>
        <div css={styles.body}>
          <div css={styles.container}>
            <MainPane style={styles.mainPane}>
              <ArticleBody id={article.id} content={article.body} />
            </MainPane>
            <SidePane style={styles.sidePane}>
              <LatestArticles />
            </SidePane>
          </div>
        </div>
        <Script src={instagramJS} async />
      </Layout>
    </>
  );
};

const styles = {
  container: css({
    margin: "0 auto",
    padding: "0 15px",
    "@media screen and (min-width: 767px)": {
      maxWidth: 768,
    },
    "@media screen and (min-width: 992px)": {
      maxWidth: 1120,
      alignItems: "stretch",
      display: "flex",
    },
    "@media screen and (min-width: 1280px)": {
      width: 1120,
    },
  }),
  header: css({
    marginBottom: 20,
    position: "relative",
  }),
  body: css({
    backgroundColor: "var(--defaultComponentBgColor)",
  }),
  mainPane: css({
    paddingBottom: 30,
    paddingTop: 30,
    "@media screen and (min-width: 992px)": {
      paddingRight: 20,
    },
  }),
  sidePane: css({
    paddingBottom: 30,
    paddingTop: 30,
  }),
};
