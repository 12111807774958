import React, { ReactChild } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { ThemeContextProvider } from "../contexts/ThemeContextProvider";

type Props = {
  children: ReactChild;
};

const isProductionReactAppEnv = process.env.REACT_APP_ENV === "production";

if (!isProductionReactAppEnv) {
  console.log(`environment: ${process.env.REACT_APP_ENV}`);
  console.log(`api: ${process.env.REACT_APP_API_URI}`);
}

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URI });

// NOTE: https://www.apollographql.com/docs/react/networking/authentication
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "x-application": process.env.REACT_APP_X_APPLICATION_HEADER,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: true,
  link: authLink.concat(httpLink),
});

Sentry.init({
  dsn: "https://0da7b433a3224dbfa012ac5d56bc410a@o1055957.ingest.sentry.io/6062179",
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ["error"],
    }),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  enabled: process.env.REACT_APP_ENV !== "development",
});

export const ContextProvider: React.FC<Props> = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <ThemeContextProvider>{children}</ThemeContextProvider>
    </ApolloProvider>
  );
};
